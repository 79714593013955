
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify'; // Optional for notifications
import { useNavigate, useParams } from 'react-router-dom'; // Use useNavigate instead of useHistory
import axios from 'axios';

import { useSelector } from 'react-redux';
import { url } from '../Common/Url';
import { useTranslation } from 'react-i18next';


const DoctorSpeakListComponent = () => {
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const { token } = useSelector((state) => state.user)

    const get_office_list = async () => {
        const { data } = await axios.get(`${url}/api/v1/doctorspeak/get/`, {
            headers: {
                'token': `${token}`
            }
        })

        console.log(data)
        setData(data.data)
    }

    useEffect(() => {
        get_office_list();
    }, [])



    const handleEdit = (id) => {

        navigate(`/admin/doctorspeak/${id}`)
    }

    const handleDelete = async (id) => {
        const confirm = window.confirm('Are you sure to delete this doctor speak ?')
        console.log(id)
        if (confirm) {
            const { data } = await axios.delete(`${url}/api/v1/doctorspeak/delete/${id}`, {
                headers: {
                    'token': `${token}`
                },
            })
            if (data.status == true) {
                toast.success(data.message)
                get_office_list();
            } else {
                toast.error(`Something went wrong!`)
            }
        }



    }


    const handleStatus = async (id, s) => {
        let status =1 ;
        if(s ==1){
            status =0;
        }else{
            status =1
        }
        const { data } = await axios.post(`${url}/api/v1/doctorspeak/status/change/${id}`, {
            status: status
        }, {
            headers: {
                'token': `${token}`
            },
        })
        console.log(data)
        if (data.status == true) {
            toast.success(data.message)
            get_office_list()
        } else {
            toast.error(`Something went wrong!`)
        }
    }

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'url',
            selector: row => row.url,
            sortable: true,
        },
      

        {
            name: 'Status',
            cell: row => (
                <>
                    <label class="switch">
                        <input type="checkbox" class="switch-input" checked={row.status == 1 ? 'true' : ''} onClick={() => handleStatus(row._id, row.status)} />
                        <span class="switch-toggle-slider">
                            {
                                row.status == 1 ? <span class="switch-on">
                                    <i class="ti ti-check"></i>
                                </span> : <span class="switch-off">
                                    <i class="ti ti-x"></i>
                                </span>
                            }


                        </span>
                        <span class="switch-label">{row.status == 1 ? 'Published' : 'Draft'}</span>
                    </label>
                </>
            ),
        },
        {
            name: 'Actions',
            cell: row => (
                <>

                    <button className="btn btn-primary btn-sm me-3" onClick={() => handleEdit(row._id, row.status)}>Edit</button>
                    <button className="btn btn-danger btn-sm" onClick={() => handleDelete(row._id, row.status)}>Delete</button>
                </>
            ),
        },
    ];

    return (
        <div className='col-md-8'>
            <div className="card mb-3">
                <h5 className="card-header">Offer List</h5>
                <div className="card-body">
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        highlightOnHover
                        striped
                    />
                </div>
            </div>
        </div>
    );
};

export default DoctorSpeakListComponent;
