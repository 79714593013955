import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { url } from "../Common/Url";
import AwardList from "./AwardList";

export default function Awards() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [entextEditor, setEnTextEditor] = useState("");
  const [artextEditor, setArTextEditor] = useState("");

  const [entitle, setEnTitle] = useState("");
  const [artitle, setArTitle] = useState("");
  const [slug, setSlug] = useState("");

  const [file, setFile] = useState("");

  const { token } = useSelector((state) => state.user);

  const [seoTitleEn, setSeoTitleEn] = useState("");
  const [seoKeywordsEn, setSeoKeywordsEn] = useState("");
  const [seoDescEn, setSeoDescEn] = useState("");
  const [seoTitleAr, setSeoTitleAr] = useState("");
  const [seoKeywordsAr, setSeoKeywordsAr] = useState("");
  const [seoDescAr, setSeoDescAr] = useState("");

  const handleAwards = async () => {
    try {
      try {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("entextEditor", entextEditor);
        formData.append("artextEditor", artextEditor);
        formData.append("entitle", entitle);
        formData.append("slug", slug);

        formData.append("seoTitleEn", seoTitleEn);
        formData.append("seoKeywordsEn", seoKeywordsEn);
        formData.append("seoDescEn", seoDescEn);
        formData.append("seoTitleAr", seoTitleAr);
        formData.append("seoKeywordsAr", seoKeywordsAr);
        formData.append("seoDescAr", seoDescAr);
        const { data } = await axios.post(
          `${url}/api/v1/user/awards/store`,
          formData,
          {
            headers: {
              token: `${token}`,
            },
          }
        );
        if (data.status == true) {
          toast(data.message);
          window.location.reload();
        }
      } catch (err) {
        if (err.response.status === 500) {
          console.log("There was a problem with the server");
        } else {
          console.log(err.response.data.msg);
        }
      }
    } catch (error) {}
  };

  const handleSetSlug = () => {};

  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        Add Awards
      </Button>
      <AwardList />
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Awards</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row g-3">
            <div class="col-sm-12">
              <label class="form-label" for="username">
                <b>Image</b>
              </label>
              <input
                type="file"
                id="username"
                class="form-control"
                filename={file}
                onChange={(e) => setFile(e.target.files[0])}
                accept="image/*"
              />
            </div>
            <div class="col-sm-12">
              <label class="form-label" for="username">
                <b>Name (En)</b>
              </label>
              <input
                type="text"
                id="username"
                class="form-control"
                value={entitle}
                onInputCapture={(e) => {
                  setEnTitle(e.target.value);
                  setSlug(e.target.value.split(" ").join("-").toLowerCase());
                }}
              />
            </div>
            <div class="col-sm-12">
              <label class="form-label" for="username">
                <b>Description (En) </b>
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={entextEditor}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();

                  setEnTextEditor(data);
                }}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "|",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "indent",
                    "outdent",
                    "|",
                    "imageUpload",
                    "blockQuote",
                    "insertTable",
                    "mediaEmbed",
                    "|",
                    "undo",
                    "redo",
                    "|",
                    "selectAll",
                  ],
                  heading: {
                    options: [
                      {
                        model: "paragraph",
                        title: "Paragraph",
                        class: "ck-heading_paragraph",
                      },
                      {
                        model: "heading1",
                        view: "h1",
                        title: "Heading 1",
                        class: "ck-heading_heading1",
                      },
                      {
                        model: "heading2",
                        view: "h2",
                        title: "Heading 2",
                        class: "ck-heading_heading2",
                      },
                      {
                        model: "heading3",
                        view: "h3",
                        title: "Heading 3",
                        class: "ck-heading_heading3",
                      },
                      {
                        model: "heading4",
                        view: "h4",
                        title: "Heading 4",
                        class: "ck-heading_heading4",
                      },
                      {
                        model: "heading5",
                        view: "h5",
                        title: "Heading 5",
                        class: "ck-heading_heading5",
                      },
                      {
                        model: "heading6",
                        view: "h6",
                        title: "Heading 6",
                        class: "ck-heading_heading6",
                      },
                    ],
                  },
                }}
              />

              <label class="form-label" for="username">
                <b>Name (Ar)</b>
              </label>
              <input
                type="text"
                dir="rtl"
                id="username"
                class="form-control"
                value={artitle}
                onChange={(e) => setArTitle(e.target.value)}
              />

              <label class="form-label" for="username">
                <b>Description (Ar) </b>
              </label>
              <CKEditor
                config={{
                  language: {
                    ui: "ar", // UI language
                    content: "ar", // Content language
                  },
                  contentsLangDirection: "rtl", // Content direction
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "|",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "indent",
                    "outdent",
                    "|",
                    "imageUpload",
                    "blockQuote",
                    "insertTable",
                    "mediaEmbed",
                    "|",
                    "undo",
                    "redo",
                    "|",
                    "selectAll",
                  ],
                  heading: {
                    options: [
                      {
                        model: "paragraph",
                        title: "Paragraph",
                        class: "ck-heading_paragraph",
                      },
                      {
                        model: "heading1",
                        view: "h1",
                        title: "Heading 1",
                        class: "ck-heading_heading1",
                      },
                      {
                        model: "heading2",
                        view: "h2",
                        title: "Heading 2",
                        class: "ck-heading_heading2",
                      },
                      {
                        model: "heading3",
                        view: "h3",
                        title: "Heading 3",
                        class: "ck-heading_heading3",
                      },
                      {
                        model: "heading4",
                        view: "h4",
                        title: "Heading 4",
                        class: "ck-heading_heading4",
                      },
                      {
                        model: "heading5",
                        view: "h5",
                        title: "Heading 5",
                        class: "ck-heading_heading5",
                      },
                      {
                        model: "heading6",
                        view: "h6",
                        title: "Heading 6",
                        class: "ck-heading_heading6",
                      },
                    ],
                  },
                }}
                editor={ClassicEditor}
                data={artextEditor}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();

                  setArTextEditor(data);
                }}
              />

              <label class="form-label" for="slug">
                <b>Slug</b>
              </label>
              <input
                type="text"
                // dir="rtl"
                id="slug"
                class="form-control"
                value={slug}
                onInputCapture={(e) => {
                  setSlug(e.target.value);
                }}
              />
              <div className="card mb-3 mt-3">
                <h5 className="card-header">SEO (En)</h5>

                <div class="card-body">
                  <div className="mb-3">
                    <label className="form-label">Keywords</label>
                    <input
                      value={seoKeywordsEn}
                      onChange={(ev) => setSeoKeywordsEn(ev.target.value)}
                      className="form-control"
                      name="seo.keywords"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Description</label>
                    <input
                      value={seoDescEn}
                      onChange={(ev) => setSeoDescEn(ev.target.value)}
                      className="form-control"
                      name="seo.description"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Title</label>
                    <input
                      value={seoTitleEn}
                      onChange={(ev) => setSeoTitleEn(ev.target.value)}
                      className="form-control"
                      name="seo.title"
                    />
                  </div>
                </div>
              </div>

              <div className="card mb-3">
                <h5 className="card-header">SEO (Ar)</h5>

                <div class="card-body">
                  <div className="mb-3">
                    <label className="form-label">Keywords</label>
                    <input
                      value={seoKeywordsAr}
                      onChange={(ev) => setSeoKeywordsAr(ev.target.value)}
                      className="form-control"
                      name="seo.keywords"
                      dir="rtl"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Description</label>
                    <input
                      value={seoDescAr}
                      onChange={(ev) => setSeoDescAr(ev.target.value)}
                      className="form-control"
                      name="seo.description"
                      dir="rtl"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Title</label>
                    <input
                      value={seoTitleAr}
                      onChange={(ev) => setSeoTitleAr(ev.target.value)}
                      className="form-control"
                      name="seo.title"
                      dir="rtl"
                    />
                  </div>
                </div>
              </div>

              <button
                class="btn btn-success btn-submit mt-4"
                onClick={handleAwards}
              >
                Add
              </button>
              <ToastContainer />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
