import React from 'react'
import PackageForm from '../../Forms/Wellness/PackageForm'
import PackageListComponent from '../../Components/Wellness/PackageListComponent'

export default function PackagePage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <PackageForm />
                <PackageListComponent />
            </div>
        </div>
    )
}
