import React from 'react'
import SeniorCareForm from '../../Forms/PatientCare/SeniorCareForm'
import SpecialClinicForm from '../../Forms/PatientCare/SpecialClinicForm'

export default function SpecialClinicPage() {
  return (
    <div>
       <SpecialClinicForm />
    </div>
  )
}
