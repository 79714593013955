import React from 'react'
import HotelForm from '../../Forms/AsterAtHome/Hotel'
import HotelList from './HotelList'

export default function Hotel() {
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
       <div className="row">
        <HotelForm />
        <HotelList />
      </div>
    </div>
  )
}
