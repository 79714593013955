import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { url, Image } from "../Components/Common/Url";
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "../i18n";
import "react-toastify/dist/ReactToastify.css";
import ImageUpload from "./ImageUpload";
import FabIcon from "./FabIcon";
import { validateEmail, validatePhoneNumber } from "../common/Validations";

const SettingsForm = () => {
  const { token } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState("global");
  const [formData, setFormData] = useState({
    title: "",
    image: "",
    number: "",
    email: "",
    address: "",
    flink: "",
    tlink: "",
    ilink: "",
    ylink: "",
    blink: "",
    fab: "",
    copyright: "",
    license: "",
    arcopyright: "",
    arlicense: "",
    androidAppLink: "",
    iosAppLink: "",
  });

  const [smtpData, setSmtpData] = useState({
    host: "",
    port: "",
    username: "",
    password: "",
    fromEmail: "",
  });

  const [scriptsData, setScriptsData] = useState({
    scripts_header: "",
    scripts_footer: "",
  });

  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSmtpInputChange = (e) => {
    const { name, value } = e.target;
    setSmtpData((prev) => ({ ...prev, [name]: value }));
  };

  const handleScripsChange = (e) => {
    const { name, value } = e.target;
    setScriptsData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSettingPage = async (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      toast.error("Invalid Email format");
      return;
    }

    if (!validatePhoneNumber(formData.number)) {
      toast.error("Invalid Phone number");
      return;
    }

    const submitData = new FormData();
    for (const key in formData) {
      submitData.append(key, formData[key]);
    }

    try {
      const { data } = await axios.post(
        `${url}/api/v1/setting/update`,
        submitData,
        {
          headers: { token },
        }
      );

      if (data.status) {
        toast.success(data.message);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      toast.error("An error occurred while updating settings.");
    }
  };

  const validateSmtpData = () => {
    toast.dismiss();

    if (!smtpData.host.trim()) {
      toast("SMTP Host is required", { containerId: "asettingd" });
      return false;
    }
    if (!smtpData.username.trim()) {
      toast("SMTP Username is required", { containerId: "asettingd" });
      return false;
    }
    if (!smtpData.password.trim()) {
      toast("SMTP Password is required", { containerId: "asettingd" });
      return false;
    }
    if (!validateEmail(smtpData.fromEmail)) {
      toast("From Email must be a valid email address", {
        containerId: "asettingd",
      });
      return false;
    }
    return true;
  };

  const handleSmtpSave = async (e) => {
    e.preventDefault();

    if (!validateSmtpData()) {
      return;
    }

    try {
      const { data } = await axios.post(
        `${url}/api/v1/setting/smtp/update`,
        smtpData,
        {
          headers: { token },
        }
      );

      if (data.status) {
        toast(data.message, { containerId: "asettingd" });
      } else {
        toast("Something went wrong!", { containerId: "asettingd" });
        toast.error("Something went wrong!");
      }
    } catch (error) {
      toast("An error occurred while updating SMTP settings.", {
        containerId: "asettingd",
      });
    }
  };

  const handleScriptsSave = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post(
        `${url}/api/v1/setting/scripts/update`,
        scriptsData,
        {
          headers: { token },
        }
      );

      if (data.status) {
        toast(data.message, { containerId: "asettingd" });
      } else {
        toast("Something went wrong!", { containerId: "asettingd" });
        toast.error("Something went wrong!");
      }
    } catch (error) {
      toast("An error occurred while updating SMTP settings.", {
        containerId: "asettingd",
      });
    }
  };

  const getSettingData = async () => {
    try {
      const { data } = await axios.get(`${url}/api/v1/setting/get`, {
        headers: { token },
      });
      setFormData(data.data);
      setSmtpData(data.data);
      setScriptsData(data.data);
    } catch (error) {
      toast.error("Failed to fetch settings data.");
    }
  };

  useEffect(() => {
    getSettingData();
  }, []);

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <ToastContainer containerId="asettingd" />
        <div className="row">
          <div className="col">
            <div className="card mb-3">
              <div className="card-header pt-2">
                <ul className="nav nav-tabs card-header-tabs" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        activeTab === "global" ? "active" : ""
                      }`}
                      onClick={() => changeTab("global")}
                    >
                      Global Settings
                    </button>
                  </li>

                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        activeTab === "smtp" ? "active" : ""
                      }`}
                      onClick={() => changeTab("smtp")}
                    >
                      SMTP Settings
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        activeTab === "hFScripts" ? "active" : ""
                      }`}
                      onClick={() => changeTab("hFScripts")}
                    >
                      Header & Footer Scripts
                    </button>
                  </li>
                </ul>
              </div>

              <div className="tab-content">
                {activeTab === "global" && (
                  <div
                    className="tab-pane fade active show card-body"
                    role="tabpanel"
                  >
                    <form onSubmit={handleSettingPage}>
                      <div className="row g-3">
                        <div className="col-md-12">
                          <label className="form-label">Website Logo</label>{" "}
                          <br />
                          {formData.image && (
                            <img
                              src={`${Image}/${formData.image}`}
                              className="d-block w-px-100 h-px-100 rounded mb-4"
                            />
                          )}
                          <ImageUpload
                            image={formData.image}
                            setImage={setFormData}
                          />
                        </div>
                        {[
                          {
                            label: t("Title"),
                            name: "title",
                            type: "text",
                            dir: "ltr",
                          },
                          {
                            label: t("Phone Number"),
                            name: "number",
                            type: "text",
                            dir: "ltr",
                          },
                          {
                            label: t("Email"),
                            name: "email",
                            type: "text",
                            dir: "ltr",
                          },
                          {
                            label: t("Address"),
                            name: "address",
                            type: "text",
                            dir: "ltr",
                          },
                          {
                            label: t("Facebook Link"),
                            name: "flink",
                            type: "text",
                            dir: "ltr",
                          },
                          {
                            label: t("Twitter Link"),
                            name: "tlink",
                            type: "text",
                            dir: "ltr",
                          },
                          {
                            label: t("Instagram Link"),
                            name: "ilink",
                            type: "text",
                            dir: "ltr",
                          },
                          {
                            label: t("Youtube Link"),
                            name: "ylink",
                            type: "text",
                            dir: "ltr",
                          },
                          {
                            label: t("Blog Link"),
                            name: "blink",
                            type: "text",
                            dir: "ltr",
                          },
                          {
                            label: t("Copyright"),
                            name: "copyright",
                            type: "text",
                            dir: "ltr",
                          },
                          {
                            label: t("License"),
                            name: "license",
                            type: "text",
                            dir: "ltr",
                          },
                          {
                            label: t("Arabic Copyright"),
                            name: "arcopyright",
                            type: "text",
                            dir: "rtl",
                          },
                          {
                            label: t("Arabic License"),
                            name: "arlicense",
                            type: "text",
                            dir: "rtl",
                          },
                          {
                            label: t("Android App Link"),
                            name: "androidAppLink",
                            type: "text",
                            dir: "ltr",
                          },
                          {
                            label: t("IOS App Link"),
                            name: "iosAppLink",
                            type: "text",
                            dir: "ltr",
                          },
                        ].map((field, idx) => (
                          <div key={idx} className="col-md-12">
                            <label className="form-label">{field.label}</label>
                            <input
                              type={field.type}
                              className="form-control"
                              name={field.name}
                              value={formData[field.name]}
                              onChange={handleInputChange}
                              dir={field.dir}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="pt-4">
                        <button type="submit" className="btn btn-primary">
                          {t("Update")}
                        </button>
                      </div>
                    </form>
                  </div>
                )}

                {activeTab === "smtp" && (
                  <div
                    className="tab-pane fade active show card-body"
                    role="tabpanel"
                  >
                    <form onSubmit={handleSmtpSave}>
                      <div className="row g-3">
                        {[
                          { label: "SMTP Host", name: "host" },
                          { label: "SMTP Port", name: "port" },
                          { label: "Username", name: "username" },
                          { label: "Password", name: "password" },
                          { label: "From Email", name: "fromEmail" },
                          {
                            label: "Authorized Redirect URI",
                            name: "authRedirectUrl",
                          },
                        ].map((field, idx) => (
                          <div key={idx} className="col-md-12">
                            <label className="form-label">{field.label}</label>
                            <input
                              type="text"
                              className="form-control"
                              name={field.name}
                              value={smtpData[field.name]}
                              onChange={handleSmtpInputChange}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="pt-4">
                        <button type="submit" className="btn btn-primary">
                          Save SMTP Settings
                        </button>
                      </div>
                    </form>
                  </div>
                )}

                {activeTab === "hFScripts" && (
                  <div
                    className="tab-pane fade active show card-body"
                    role="tabpanel"
                  >
                    <form onSubmit={handleScriptsSave}>
                      <div className="row g-3">
                        {[
                          {
                            label: "Scripts in Header",
                            name: "scripts_header",
                          },
                          {
                            label: "Scripts in Footer",
                            name: "scripts_footer",
                          },
                        ].map((field, idx) => (
                          <div key={idx} className="col-md-12">
                            <label className="form-label">{field.label}</label>
                            <textarea
                              className="form-control"
                              name={field.name}
                              value={scriptsData[field.name]}
                              onChange={handleScripsChange}
                              rows={20} // Adjust the number of rows as needed for larger text area
                            />
                          </div>
                        ))}
                      </div>
                      <div className="pt-4">
                        <button type="submit" className="btn btn-primary">
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsForm;
