import React, { useState } from 'react'
import DmHealthcare from './DmHealthcare'
import AsterClinic from './AsterClinic'
import ChairMan from './ChairMan'
import ExecutiveMember from './ExecutiveMember'
import Awards from './Awards'
import Value from './Value'
import Vission from './Vission'



export default function AboutComponent() {


    return (
        <div class="container-xxl flex-grow-1 container-p-y">
            <div class="col-xl-12">
                <h6 class="text-muted">About us Menu</h6>
                <div class="nav-align-left nav-tabs-shadow mb-4">
                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                            <button
                                type="button"
                                class="nav-link active"
                                role="tab"
                                data-bs-toggle="tab"
                                data-bs-target="#navs-left-home"
                                aria-controls="navs-left-home"
                                aria-selected="true">
                                Aster DM Healthcare
                            </button>
                        </li>
                        <li class="nav-item">
                            <button
                                type="button"
                                class="nav-link"
                                role="tab"
                                data-bs-toggle="tab"
                                data-bs-target="#navs-left-profile"
                                aria-controls="navs-left-profile"
                                aria-selected="false">
                                Aster Clinic
                            </button>
                        </li>
                        <li class="nav-item">
                            <button
                                type="button"
                                class="nav-link"
                                role="tab"
                                data-bs-toggle="tab"
                                data-bs-target="#navs-left-vission"
                                aria-controls="navs-left-vission"
                                aria-selected="false">
                              Vision & Mission
                            </button>
                        </li>
                        <li class="nav-item">
                            <button
                                type="button"
                                class="nav-link"
                                role="tab"
                                data-bs-toggle="tab"
                                data-bs-target="#navs-left-value"
                                aria-controls="navs-left-value"
                                aria-selected="false">
                                Value
                            </button>
                        </li>
                        <li class="nav-item">
                            <button
                                type="button"
                                class="nav-link"
                                role="tab"
                                data-bs-toggle="tab"
                                data-bs-target="#navs-left-messages"
                                aria-controls="navs-left-messages"
                                aria-selected="false">
                                Chairman's Message
                            </button>
                        </li>
                        <li class="nav-item">
                            <button
                                type="button"
                                class="nav-link"
                                role="tab"
                                data-bs-toggle="tab"
                                data-bs-target="#navs-left-executive"
                                aria-controls="navs-left-executive"
                                aria-selected="false">
                                Executive Management
                            </button>
                        </li>
                        <li class="nav-item">
                            <button
                                type="button"
                                class="nav-link"
                                role="tab"
                                data-bs-toggle="tab"
                                data-bs-target="#navs-left-awards"
                                aria-controls="navs-left-awards"
                                aria-selected="false">
                                Awards
                            </button>
                        </li>

                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active" id="navs-left-home">
                            <DmHealthcare />
                        </div>


                        <div class="tab-pane fade" id="navs-left-profile">
                            <AsterClinic />
                        </div>
                        <div class="tab-pane fade" id="navs-left-vission">
                           <Vission />
                        </div>
                        <div class="tab-pane fade" id="navs-left-value">
                            <Value />
                        </div>
                        <div class="tab-pane fade" id="navs-left-messages">
                            <ChairMan />
                        </div>
                        <div class="tab-pane fade" id="navs-left-executive">
                            <ExecutiveMember />
                        </div>
                        <div class="tab-pane fade" id="navs-left-awards">
                            <Awards />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
