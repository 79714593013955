import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import ImageDropzone from "../Forms/Fields/ImageDropzone";
import "../i18n";
import { Tab, Nav } from "react-bootstrap";
import FormServices from "../Services/FormServices";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { BASE_URL } from "../Components/Common/Url";

const statusOptions = [
  { value: "publish", label: "Publish" },
  { value: "unpublished", label: "Unpublish" },
];

const getValidationSchema = (key) => {
  return Yup.object().shape({
    titleEn:
      key === "english"
        ? Yup.string().required("Title is required")
        : Yup.string(),
    titleAr:
      key === "arabic"
        ? Yup.string().required("Title is required")
        : Yup.string(),
    status: Yup.string().required("Status is required"),
    // imageEn: key === 'english' ? Yup.mixed().required('An image is required') : Yup.mixed(),
    // imageAr: key === 'arabic' ? Yup.mixed().required('An image is required') : Yup.mixed(),
  });
};

const EditFacilityForm = ({ facility }) => {
  const navigate = useNavigate();
  const [key, setKey] = useState("english");
  const [initialValues, setInitialValues] = useState({
    titleEn: "",
    titleAr: "",
    status: "",
    imageEn: "",
    imageAr: "",
  });

  useEffect(() => {
    toast.dismiss();
    const fetchSpecialty = async () => {
      try {
        const data = facility;
        setInitialValues({
          titleEn: data.titleEn || "",
          titleAr: data.titleAr || "",
          status: data.status || "",
          imageEn: data.imageEn ? BASE_URL + data.imageEn : "",
          imageAr: data.imageAr ? BASE_URL + data.imageAr : "",
        });
      } catch (error) {
        toast.error("Error fetching specialty data");
      }
    };
    fetchSpecialty();
  }, []);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={() => getValidationSchema(key)}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            await FormServices.updateFacility(facility._id, values);
            toast.success("Facility updated successfully!");
          } catch (error) {
            console.error("Error updating facility:", error);
            toast.error("Error updating facility");
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ setFieldValue, isSubmitting }) => (
          <Form className="container mt-4">
            <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="english">English</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="arabic">Arabic</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="english">
                  <div className="mb-3">
                    <label htmlFor="titleEn" className="form-label">
                      Title (English)
                    </label>
                    <Field
                      name="titleEn"
                      type="text"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="titleEn"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Image (English)</label>
                    <ImageDropzone
                      onFilesAdded={(acceptedFiles) => {
                        if (acceptedFiles.length > 0) {
                          setFieldValue("imageEn", acceptedFiles[0]);
                        }
                      }}
                    />
                    {initialValues.imageEn && (
                      <div className="m-2 d-flex flex-column">
                        <img
                          src={initialValues.imageEn}
                          alt="Uploaded"
                          style={{ maxWidth: "100px", height: "auto" }}
                        />
                      </div>
                    )}
                    <ErrorMessage
                      name="imageEn"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="arabic">
                  <div className="mb-3">
                    <label htmlFor="titleAr" className="form-label">
                      Title (Arabic)
                    </label>
                    <Field
                      dir="rtl"
                      name="titleAr"
                      type="text"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="titleAr"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Image (Arabic)</label>
                    <ImageDropzone
                      onFilesAdded={(acceptedFiles) => {
                        if (acceptedFiles.length > 0) {
                          setFieldValue("imageAr", acceptedFiles[0]);
                        }
                      }}
                    />
                    {initialValues.imageAr && (
                      <div className="m-2 d-flex flex-column">
                        <img
                          src={initialValues.imageAr}
                          alt="Uploaded"
                          style={{ maxWidth: "100px", height: "auto" }}
                        />
                      </div>
                    )}

                    <ErrorMessage
                      name="imageAr"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </Tab.Pane>
                <div className="mb-3">
                  <label className="form-label">Status</label>
                  <Select
                    options={statusOptions}
                    onChange={(option) => setFieldValue("status", option.value)}
                    value={statusOptions.find(
                      (option) => option.value === initialValues.status
                    )}
                  />
                  <ErrorMessage
                    name="status"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                </div>
              </Tab.Content>
            </Tab.Container>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditFacilityForm;
