
import React from 'react'
import ContactForm from '../../Forms/ContactForm';
export default function ContactComponent() {

    return (
        <ContactForm />

    )
}
