import React from 'react'
import ProgramForm from '../../Forms/Wellness/ProgramForm'
import ProgramListComponent from '../../Components/Wellness/ProgramListComponent'

export default function ProgramPage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
            <ProgramForm />
            <ProgramListComponent />
            </div>
        </div>
    )
}
