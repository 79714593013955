import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import FormServices from "../Services/FormServices"; // Adjust import path as needed
import SpecialityFAQList from "./SpecialityFAQList"; // Component to list FAQs
import SpecialityFAQForm from "./SpecialityFAQForm"; // Component for adding/editing FAQs
import { toast } from "react-toastify";

const ManageSpecialityFAQPage = () => {
  const { id } = useParams(); // Specialty ID
  const [faqs, setFAQs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchFAQs();
  }, []);

  const fetchFAQs = async () => {
    setLoading(true);
    try {
      const data = await FormServices.fetchFAQsBySpecialty(id); // Replace with actual API call
      setFAQs(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddFAQ = async (formData) => {
    toast.dismiss();
    try {
      await FormServices.createFAQ(formData, id); // Replace with actual API call
      toast.success("FAQ added successfully");
      fetchFAQs();
    } catch (err) {
      toast.error("Error adding FAQ");
    }
  };

  const handleUpdateFAQ = async (faqId, formData) => {
    toast.dismiss();
    console.log("updaye ==>", formData);
    try {
      await FormServices.updateFAQ(faqId, formData); // Replace with actual API call
      toast.success("FAQ updated successfully");
      fetchFAQs();
    } catch (err) {
      toast.error("Error updating FAQ");
    }
  };

  const handleDeleteFAQ = async (faqId) => {
    toast.dismiss();
    const confirmed = window.confirm(
      "Are you sure you want to delete this FAQ?"
    );
    if (confirmed) {
      try {
        await FormServices.deleteFAQ(faqId); // Replace with actual API call
        toast.success("FAQ deleted successfully");
        fetchFAQs();
      } catch (err) {
        toast.error("Error deleting FAQ");
      }
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="col">
          <div className="card mb-3">
            <h5 className="card-header">Manage FAQs</h5>
            <div className="card-body">
              <Tab.Container id="faq-tabs" defaultActiveKey="english">
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="english">English FAQs</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="arabic">Arabic FAQs</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="english">
                    <SpecialityFAQForm
                      language="english"
                      onSubmit={handleAddFAQ}
                    />
                    <SpecialityFAQList
                      faqs={faqs.filter((faq) => faq.language === "english")}
                      onDelete={handleDeleteFAQ}
                      onUpdateFAQ={handleUpdateFAQ}
                      language="english"
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="arabic">
                    <SpecialityFAQForm
                      language="arabic"
                      onSubmit={handleAddFAQ}
                    />
                    <SpecialityFAQList
                      faqs={faqs.filter((faq) => faq.language === "arabic")}
                      onDelete={handleDeleteFAQ}
                      onUpdateFAQ={handleUpdateFAQ}
                      language="arabic"
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageSpecialityFAQPage;
