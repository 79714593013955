import React, { useState, useRef } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import EnAddClinicForm from '../../Forms/EnAddClinicForm';
import ArAddClinicForm from '../../Forms/ArAddClinicForm';

export default function Clinic() {
    const [key, setKey] = useState('english');


    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">

                <div className="row">
                    <div className="col">

                        <div className="card mb-3">
                            <h5 className="card-header">Add New Clinic</h5>
                            <div className="card-header pt-2">
                                <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                                    <Nav variant="tabs">
                                        <Nav.Item>
                                            <Nav.Link eventKey="english">English</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="arabic">Arabic</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="english">
                                            <EnAddClinicForm />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="arabic">
                                            <ArAddClinicForm />
                                        </Tab.Pane>

                                    </Tab.Content>

                                </Tab.Container>
                            </div>





                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
