import React from 'react'
import ClientForm from '../../Forms/Wellness/ClientForm'
import ClientListComponent from '../../Components/Wellness/ClientListComponent'

export default function ClientPage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <ClientForm />
                <ClientListComponent />
            </div>
        </div>
    )
}
