import React from 'react'
import TrustForm from '../../Forms/Teleconsultant/TrustForm'
import TrustListComponent from '../../Components/Teleconsultant/TrustListComponent'

export default function Trust() {
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <TrustForm />
        <TrustListComponent />
      </div>
    </div>
  )
}
