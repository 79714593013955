import React from 'react'
import CareersForm from '../Forms/CareersForm'

export default function CareerPage() {
    return (
        <div className='container-xxl flex-grow-1 container-p-y'>
            <div className='row'>
                <CareersForm />
            </div>

        </div>
    )
}
