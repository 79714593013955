import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import { useSelector } from "react-redux";
import axios from "axios";
import Select from "react-select";
import { url, Image } from "../../Common/Url";
import { ToastContainer, toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Alert } from "react-bootstrap";
import FormServices from "../../../Services/FormServices";

const AddNews = ({ type }) => {
  const params = useParams();
  const { id } = params;

  const { token } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [metakeywords, setMetakeywords] = useState("");
  const [slug, setSlug] = useState("");
  const [contactForm, setContactForm] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState();
  const [doctors, setDoctors] = useState([]);
  const [externalLink, setExternalLink] = useState("");
  const fetchDoctors = async () => {
    try {
      const data = await FormServices.fetchDoctors();
      console.log(data);
      setDoctors(data);
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    fetchDoctors();
  }, []);
  const handleDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file.type.startsWith("image/")) {
      setImage(file);
    } else {
      toast.error("Invalid file type. Please upload an image file.");
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ["image/*"],
    onDrop: handleDrop,
  });

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  const handleBlog = async () => {
    if (!title) {
      return toast.error("Please Enter Title");
    }

    try {
      const { data } = await axios.post(
        `${url}/api/v1/news/add`,
        {
          lan: activeLanguage,
          title: title,
          image: image,
          description: description,
          metatitle: metatitle,
          metadescription: metadescription,
          metakeywords: metakeywords,
          slug: slug,
          externalLink: externalLink,
          doctor: selectedDoctor,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      if (data.status === true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (id) {
      const getStepData = async () => {
        const { data } = await axios.get(`${url}/api/v1/news/get/${id}`, {
          headers: {
            token: `${token}`,
          },
        });

        console.log(data.data);
        if (activeLanguage === "ar") {
          setDescription(data.data.ar_description);
          setTitle(data.data.ar_title);
          setImage(data.data.ar_image);
          setMetatitle(data.data.ar_metatitle);
          setMetakeywords(data.data.ar_metakeywords);
          setMetadescription(data.data.ar_metadescription);
          setSlug(data.data.ar_slug);
          setExternalLink(data.data.ar_external_link);
          setSelectedDoctor(data.data.ar_doctor);
        } else {
          setDescription(data.data.en_description);
          setTitle(data.data.en_title);
          setImage(data.data.en_image);
          setMetatitle(data.data.en_metatitle);
          setMetakeywords(data.data.en_metakeywords);
          setMetadescription(data.data.en_metadescription);
          setSlug(data.data.en_slug);
          setExternalLink(data.data.en_external_link);
          setSelectedDoctor(data.data.en_doctor);
        }
      };
      getStepData();
    } else {
      setDescription("");
      setTitle("");
      setImage("");
      setMetatitle("");
      setMetakeywords("");
      setMetadescription("");
      setContactForm(false);
      setSlug("");
      setExternalLink("");
    }
  }, [id, activeLanguage]);

  const handleStepUpdate = async () => {
    try {
      const { data } = await axios.put(
        `${url}/api/v1/news/update/${id}`,
        {
          lan: activeLanguage,
          title: title,
          image: image,
          description: description,
          metatitle: metatitle,
          metadescription: metadescription,
          metakeywords: metakeywords,
          slug: slug,
          externalLink: externalLink,
          doctor: selectedDoctor,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      if (data.status === true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleTitle = (e) => {
    setTitle(e.target.value);
    let stringWithoutSpaces = e.target.value.replace(/\s+/g, "-");
    setSlug(stringWithoutSpaces.toLowerCase());
  };

  return (
    <>
      <div className="col-lg-8">
        <div className="card mb-3">
          <ToastContainer />
          <div className="card-header pt-2">
            <h4>{id ? `Edit news ${title}` : "Add news"}</h4>

            <ul className="nav nav-tabs card-header-tabs" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activeLanguage === "en" ? "active" : ""
                  }`}
                  onClick={() => changeLanguage("en")}
                >
                  English
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activeLanguage === "ar" ? "active" : ""
                  }`}
                  onClick={() => changeLanguage("ar")}
                >
                  Arabic
                </button>
              </li>
            </ul>
          </div>

          <div className="tab-content">
            <div className="tab-pane fade active show" role="tabpanel">
              <div>
                <div className="row g-3">
                  <div className="col-md-12">
                    <label className="form-label">{t("Title")}</label>
                    <input
                      type="text"
                      dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                      className="form-control"
                      name="name"
                      value={title}
                      onChange={handleTitle}
                    />
                  </div>

                  <div className="col-md-12">
                    <label className="form-label">{t("Description")}</label>
                    <CKEditor
                      key={`editor1-${activeLanguage}`}
                      config={{
                        language: {
                          ui: activeLanguage === "ar" ? "ar" : "en", // UI language
                          content: activeLanguage === "ar" ? "ar" : "en", // Content language
                        },
                        contentsLangDirection:
                          activeLanguage === "ar" ? "rtl" : "ltr", // Content direction
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "|",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "|",
                            "indent",
                            "outdent",
                            "|",
                            "imageUpload",
                            "blockQuote",
                            "insertTable",
                            "mediaEmbed",
                            "|",
                            "undo",
                            "redo",
                            "|",
                            "selectAll",
                          ],
                          heading: {
                            options: [
                              {
                                model: "paragraph",
                                title: "Paragraph",
                                class: "ck-heading_paragraph",
                              },
                              {
                                model: "heading1",
                                view: "h1",
                                title: "Heading 1",
                                class: "ck-heading_heading1",
                              },
                              {
                                model: "heading2",
                                view: "h2",
                                title: "Heading 2",
                                class: "ck-heading_heading2",
                              },
                              {
                                model: "heading3",
                                view: "h3",
                                title: "Heading 3",
                                class: "ck-heading_heading3",
                              },
                              {
                                model: "heading4",
                                view: "h4",
                                title: "Heading 4",
                                class: "ck-heading_heading4",
                              },
                              {
                                model: "heading5",
                                view: "h5",
                                title: "Heading 5",
                                class: "ck-heading_heading5",
                              },
                              {
                                model: "heading6",
                                view: "h6",
                                title: "Heading 6",
                                class: "ck-heading_heading6",
                              },
                            ],
                          },
                      }}
                      editor={ClassicEditor}
                      data={description}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setDescription(data);
                      }}
                    />
                  </div>

                  <div className="col-md-12">
                    <label className="form-label">{t("Meta Title")}</label>
                    <input
                      type="text"
                      dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                      className="form-control"
                      name="name"
                      value={metatitle}
                      onChange={(e) => setMetatitle(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">
                      {t("Meta Description")}
                    </label>
                    <input
                      type="text"
                      dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                      className="form-control"
                      name="name"
                      value={metadescription}
                      onChange={(e) => setMetadescription(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">{t("Meta Keywords")}</label>
                    <input
                      type="text"
                      dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                      className="form-control"
                      name="name"
                      value={metakeywords}
                      onChange={(e) => setMetakeywords(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">{t("slug")}</label>
                    <input
                      type="text"
                      dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                      className="form-control"
                      name="name"
                      value={slug}
                      onChange={(e) => setSlug(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">{t("External Link")}</label>
                    <input
                      placeholder="Enter any external link for the news"
                      type="text"
                      dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                      className="form-control"
                      name="name"
                      value={externalLink}
                      onChange={(e) => setExternalLink(e.target.value)}
                    />
                  </div>
                  <div
                    className="col-md-12"
                    style={{ display: "flex", gap: "2%" }}
                  ></div>
                  <div className="mb-3">
                    <label className="form-label">{t("Doctors")}</label>
                    <Select
                      dir="rtl"
                      name="doctorDetails.clinics"
                      value={selectedDoctor}
                      options={doctors.map((doctor) => ({
                        value: doctor?._id,
                        label: doctor?.[i18n.language]?.title,
                      }))}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(selectedOption) => {
                        console.log(selectedOption);
                        setSelectedDoctor(selectedOption);
                      }}
                    />
                  </div>
                </div>
                <div className="pt-4">
                  {id ? (
                    <button
                      type="submit"
                      className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                      onClick={() => handleStepUpdate()}
                    >
                      {t("Update")}
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                      onClick={handleBlog}
                    >
                      {t("Submit")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card mb-3">
          <div className="card-body">
            <div className="row g-3">
              <div className="col-md-12">
                <label className="form-label">{t("Image")}</label>

                <div {...getRootProps()} style={dropzoneStyles}>
                  <input {...getInputProps()} />
                  <p>
                    {t(
                      "Drag and drop an image file here, or click to select one"
                    )}
                  </p>
                </div>
                {image && image.path}
                <div
                  className=""
                  style={{ textAlign: "center", marginTop: "15px" }}
                >
                  {image && (
                    <img src={`${Image}/${image}`} width="200px" alt="Blog" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default AddNews;
