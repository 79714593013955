import React from 'react'
import FaqForm from '../../Forms/AsterAtHome/FaqForm'
import FaqList from './FaqList'

export default function Faq() {
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
       <div className="row">
        <FaqForm />
        <FaqList />
      </div>
    </div>

  )
}
