import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from "react-dropzone";

import '../../i18n';

import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Image, url } from '../../Components/Common/Url';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';


const OfficeForm = () => {
    const { token } = useSelector((state) => state.user)
    const params = useParams()
    const { id } = params;
    const [image, setImage] = useState("");

    const { t, i18n } = useTranslation();
    const [activeLanguage, setActiveLanguage] = useState(i18n.language);
    const [title, setTitle] = useState("");
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setActiveLanguage(lng);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!title) {
            return toast.error(`Please Enter Title`)
        }

        const { data } = await axios.put(
            `${url}/api/v1/asterathome/office/add`,
            {
                lan: activeLanguage,
                title: title,
                image: image,

            },
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    token: `${token}`,
                },
            }
        );



        toast(data.message)
        if (data.status == true) {
            setInterval(() => {
                window.location.reload()
            }, 3000);
        }


    };



    const get_signle_office = async () => {
        const response = await fetch(`${url}/api/v1/asterathome/office/get/office/${id}`, {
            method: "GET",
            headers: {
                "token": `${token}`,

            },

        })
        const result = await response.json()
        console.log('single data', result)
        if (activeLanguage == 'ar') {
            setTitle(result.data.ar_title)
            setImage(result.data.ar_image)
        } else {
            setTitle(result.data.en_title)
            setImage(result.data.en_image)
        }
    }

    useEffect(() => {
        if (id) {
            get_signle_office()
        } else {
            setTitle('')
        }

    }, [id, activeLanguage])

    const handleDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file.type.startsWith("image/")) {
            setImage(file);
        } else {
            toast.error("Invalid file type. Please upload an image file.");
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: ["image/*"],
        onDrop: handleDrop,
    });


    const handleupdate = async (e) => {
        e.preventDefault()
        try {

            const { data } = await axios.put(
                `${url}/api/v1/asterathome/office/${id}`,
                {
                    lan: activeLanguage,
                    title: title,
                    image: image,

                },
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        token: `${token}`,
                    },
                }
            );

            toast.success(data.message)
            if (data.status == true) {
                setInterval(() => {
                    window.location.reload()
                }, 3000);
            }
        } catch (error) {
            console.log('error', error)
            toast.error(error)
        }
    }






    return (
        <div className="col-md-4">
            <ToastContainer />
            <div className="card mb-3">
                <div className="card-header pt-2">

                    <ul className="nav nav-tabs card-header-tabs" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className={`nav-link ${activeLanguage === 'en' ? 'active' : ''}`} onClick={() => changeLanguage('en')} >English</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={`nav-link ${activeLanguage === 'ar' ? 'active' : ''}`} onClick={() => changeLanguage('ar')} >Arabic</button>
                        </li>
                    </ul>
                </div>

                <div className="tab-content">
                    <div className="tab-pane fade active show" role="tabpanel">
                        <form onSubmit={id ? handleupdate : handleSubmit}>
                            <div className="row g-3">
                                <div className="col-md-12">
                                    <label className="form-label">{t("Image")}</label>

                                    <div {...getRootProps()} style={dropzoneStyles}>
                                        <input {...getInputProps()} />
                                        <p>
                                            {t(
                                                "Drag and drop an image file here, or click to select one"
                                            )}
                                        </p>
                                    </div>
                                    {image && image.path}
                                    <div className="" style={{ textAlign: "center" }}>
                                        {image && (
                                            <img src={`${Image}/${image}`} width="200px" alt="office work" />
                                        )}
                                    </div>
                                </div>
                                <div className='col-lg-12'>
                                    <label className="form-label">{t('Title')}</label>
                                    <input dir={activeLanguage === 'ar' ? 'rtl' : 'ltr'} type="text" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} />
                                </div>

                            </div>
                            <div class="pt-4">
                                <button type="submit" class="btn btn-primary me-sm-3 me-1 waves-effect waves-light">{t('Submit')}</button>

                            </div>
                        </form>
                    </div>

                </div>

            </div>
        </div>
    );
};
const dropzoneStyles = {
    border: "2px dashed #cccccc",
    borderRadius: "4px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
};
export default OfficeForm;
