import React, { useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import ImageDropzone from "../Forms/Fields/ImageDropzone";
import "../i18n";
import { Tab, Nav } from "react-bootstrap";
import FormServices from "../Services/FormServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// Options for the status dropdown
const statusOptions = [
  { value: "publish", label: "Publish" },
  { value: "unpublished", label: "Unpublish" },
];

const getValidationSchema = (key) => {
  return Yup.object().shape({
    titleEn:
      key === "english"
        ? Yup.string().required("Title is required")
        : Yup.string(),
    titleAr:
      key === "arabic"
        ? Yup.string().required("Title is required")
        : Yup.string(),
    status: Yup.string().required("Status is required"),
    // imageEn: key === 'english' ? Yup.mixed().required('An image is required') : Yup.mixed(),
    // imageAr: key === 'arabic' ? Yup.mixed().required('An image is required') : Yup.mixed(),
  });
};

const FacilityForm = ({ onCreate }) => {
  const dropzoneRef = useRef(null);

  const navigate = useNavigate();
  const [key, setKey] = useState("english");

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="card mb-3">
            <h5 className="card-header">Add New Facility</h5>
            <div className="card-header pt-2">
              <Formik
                initialValues={{
                  titleEn: "",
                  titleAr: "",
                  status: "",
                  imageEn: "",
                  imageAr: "",
                }}
                validationSchema={() => getValidationSchema(key)}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  try {
                    await FormServices.createFacility(values);
                    toast.success("Facility has been added successfully!");
                    resetForm();
                    onCreate();
                    if (dropzoneRef.current) {
                      dropzoneRef.current.removeAllFiles();
                    }
                  } catch (error) {
                    console.error("Error submitting form:", error);
                  } finally {
                    setSubmitting(false);
                  }
                }}
              >
                {({ setFieldValue, isSubmitting }) => (
                  <Form className="container mt-4">
                    <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                      <Nav variant="tabs">
                        <Nav.Item>
                          <Nav.Link eventKey="english">English</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="arabic">Arabic</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="english">
                          <div className="mb-3">
                            <label htmlFor="titleEn" className="form-label">
                              Title (English)
                            </label>
                            <Field
                              name="titleEn"
                              type="text"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="titleEn"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Image (English)
                            </label>
                            <ImageDropzone
                              onFilesAdded={(acceptedFiles) => {
                                if (acceptedFiles.length > 0) {
                                  console.log(acceptedFiles[0]);
                                  setFieldValue("imageEn", acceptedFiles[0]);
                                }
                              }}
                            />
                            <ErrorMessage
                              name="imageEn"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="arabic">
                          <div className="mb-3">
                            <label htmlFor="titleAr" className="form-label">
                              Title (Arabic)
                            </label>
                            <Field
                              dir="rtl"
                              name="titleAr"
                              type="text"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="titleAr"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Image (Arabic)</label>
                            <ImageDropzone
                              onFilesAdded={(acceptedFiles) => {
                                if (acceptedFiles.length > 0) {
                                  console.log(acceptedFiles[0]);
                                  setFieldValue("imageAr", acceptedFiles[0]);
                                }
                              }}
                            />
                            <ErrorMessage
                              name="imageAr"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Tab.Pane>
                        <div className="mb-3">
                          <label className="form-label">Status</label>
                          <Select
                            options={statusOptions}
                            onChange={(option) =>
                              setFieldValue("status", option.value)
                            }
                          />
                          <ErrorMessage
                            name="status"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isSubmitting}
                          >
                            Submit
                          </button>
                        </div>
                      </Tab.Content>
                    </Tab.Container>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FacilityForm;
