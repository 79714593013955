import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "../../i18n";

import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { url } from "../../Components/Common/Url";
import { Link, useParams } from "react-router-dom";

const VitaminFaqForm = () => {
  const { token } = useSelector((state) => state.user);
  const params = useParams();
  const { id } = params;

  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [answer, setAnswer] = useState("");
  const [question, setQuestion] = useState("");
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!question && !answer) {
      return toast(`Please Enter Question and Answer`);
    }
    const response = await fetch(`${url}/api/v1/vitamin/faq/add`, {
      method: "POST",
      headers: {
        token: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        lan: activeLanguage,
        answer,
        question,
      }),
    });
    const result = await response.json();

    toast(result.message);
    if (result.status == true) {
      setInterval(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const get_signle_faq = async () => {
    const response = await fetch(`${url}/api/v1/vitamin/faq/get/${id}`, {
      method: "GET",
      headers: {
        token: `${token}`,
      },
    });
    const result = await response.json();
    console.log("single data", result);
    if (activeLanguage == "ar") {
      setQuestion(result.data.ar_question);
      setAnswer(result.data.ar_answer);
    } else {
      setQuestion(result.data.en_question);
      setAnswer(result.data.en_answer);
    }
  };

  useEffect(() => {
    if (id) {
      get_signle_faq();
    } else {
      setQuestion("");
      setAnswer("");
    }
  }, [id, activeLanguage]);

  const handleupdate = async (e) => {
    e.preventDefault();
    console.log(answer);
    console.log(question);
    try {
      const response = await fetch(
        `${url}/api/v1/vitamin/faq/update/faq/${id}`,
        {
          method: "PUT",
          headers: {
            token: `${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            lan: activeLanguage,
            answer,
            question,
          }),
        }
      );

      const result = await response.json();
      console.log(result);
      toast.success(result.message);
      if (result.status == true) {
        setInterval(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error);
    }
  };

  return (
    <div className="col-md-4">
      <ToastContainer />
      <div className="card mb-3">
        <div className="card-header pt-2">
          <ul className="nav nav-tabs card-header-tabs" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeLanguage === "en" ? "active" : ""
                }`}
                onClick={() => changeLanguage("en")}
              >
                English
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeLanguage === "ar" ? "active" : ""
                }`}
                onClick={() => changeLanguage("ar")}
              >
                Arabic
              </button>
            </li>
          </ul>
        </div>

        <div className="tab-content">
          <div className="tab-pane fade active show" role="tabpanel">
            <form onSubmit={id ? handleupdate : handleSubmit}>
              <div className="row g-3">
                <div className="col-lg-12">
                  <label className="form-label">{t("Question")}</label>
                  <input
                    dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                    type="text"
                    className="form-control"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                  />
                </div>
                <div className="col-md-12">
                  <label className="form-label">{t("Answer")}</label>
                  <CKEditor
                    key={`editor1-${activeLanguage}`}
                    config={{
                      language: {
                        ui: activeLanguage === "ar" ? "ar" : "en", // UI language
                        content: activeLanguage === "ar" ? "ar" : "en", // Content language
                      },
                      contentsLangDirection:
                        activeLanguage === "ar" ? "rtl" : "ltr", // Content direction
                        toolbar: [
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "|",
                          "link",
                          "bulletedList",
                          "numberedList",
                          "|",
                          "indent",
                          "outdent",
                          "|",
                          "imageUpload",
                          "blockQuote",
                          "insertTable",
                          "mediaEmbed",
                          "|",
                          "undo",
                          "redo",
                          "|",
                          "selectAll",
                        ],
                        heading: {
                          options: [
                            {
                              model: "paragraph",
                              title: "Paragraph",
                              class: "ck-heading_paragraph",
                            },
                            {
                              model: "heading1",
                              view: "h1",
                              title: "Heading 1",
                              class: "ck-heading_heading1",
                            },
                            {
                              model: "heading2",
                              view: "h2",
                              title: "Heading 2",
                              class: "ck-heading_heading2",
                            },
                            {
                              model: "heading3",
                              view: "h3",
                              title: "Heading 3",
                              class: "ck-heading_heading3",
                            },
                            {
                              model: "heading4",
                              view: "h4",
                              title: "Heading 4",
                              class: "ck-heading_heading4",
                            },
                            {
                              model: "heading5",
                              view: "h5",
                              title: "Heading 5",
                              class: "ck-heading_heading5",
                            },
                            {
                              model: "heading6",
                              view: "h6",
                              title: "Heading 6",
                              class: "ck-heading_heading6",
                            },
                          ],
                        },
                    }}
                    editor={ClassicEditor}
                    data={answer}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();

                      setAnswer(data);
                    }}
                  />
                </div>
              </div>
              <div class="pt-4">
                <button
                  type="submit"
                  class="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                >
                  {t("Submit")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VitaminFaqForm;
