import React from 'react'
import TrustForm from '../../Forms/RtPcr/TrustForm'
import TrustListComponent from '../../Components/RtPcr/TrustListComponent'

export default function TrustPage() {
    return (
        <div>
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row">
                  <TrustForm />
                  <TrustListComponent />
                </div>
            </div>
        </div>
    )
}
