import React from 'react'
import SpecialServiceForm from '../../Forms/SpecialService/SpecialServiceForm'
import SpecialServicesListComponent from '../../Components/SpecialService/SpecialServicesListComponent'

export default function SpecialServicePage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
             <SpecialServiceForm />
             <SpecialServicesListComponent />
            </div>
        </div>
    )
}
