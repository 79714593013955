import React from 'react'
import DoctorSpeakForm from '../../Forms/SkinCare/DoctorSpeakForm'
import DoctorSpeakListComponent from '../../Components/Skincare/DoctorSpeakListComponent'

export default function DoctorSpeakPage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <DoctorSpeakForm />
                <DoctorSpeakListComponent />
            </div>
        </div>
    )
}
