import React from 'react'
import FooterForm from '../../Forms/Footer/FooterForm'
import FooterListComponent from '../../Components/Footer/FooterListComponent'

export default function FooterPage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
           <div className="row">
                <FooterForm />
                <FooterListComponent />

            </div>
        </div>
    )
}
