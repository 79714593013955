import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import { useSelector } from "react-redux";
import axios from "axios";
import { url, Image } from "../../../Components/Common/Url";
import { ToastContainer, toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Alert } from "react-bootstrap";

const AddBlogForm = ({ type }) => {
  const params = useParams();
  const { id } = params;

  const { token } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [metakeywords, setMetakeywords] = useState("");
  const [slug, setSlug] = useState("");
  const [category, setCategory] = useState([]);
  const [tag, setTag] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedTag, setSelectedTags] = useState([]);
  const [features, setFeatures] = useState([
    "Free Heart Checkup",
    "Dental Checkup",
    "Eye Test",
  ]);

  const [formSelections, setFormSelections] = useState([
    { id: 1, name: "Form With Full Name" },
    { id: 2, name: "Form With First & Last Name" },
    { id: 3, name: "Form With Clinic" },
    { id: 4, name: "Form With Insurance" },
  ]);
  const [featureName, setFeatureName] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [formSubmissionNote, setFormSubmissionNote] = useState("");
  const [formSelection, setFormSelection] = useState("");

  const [date, setDate] = useState(null);
  const [timeFrom, setTimeFrom] = useState(null);
  const [timeTo, setTimeTo] = useState(null);
  const [contactForm, setContactForm] = useState(false);

  const navigate = useNavigate();

  const handleDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file.type.startsWith("image/")) {
      setImage(file);
    } else {
      toast.error("Invalid file type. Please upload an image file.");
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ["image/*"],
    onDrop: handleDrop,
  });

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  const handleSubmitEventData = async () => {
    if (!title) {
      return toast.error("Please Enter Title");
    }

    try {
      const { data } = await axios.post(
        `${url}/api/v1/event/add`,
        {
          lan: activeLanguage,
          title: title,
          image: image,
          description: description,
          metatitle: metatitle,
          metadescription: metadescription,
          metakeywords: metakeywords,
          slug: slug,
          location: eventLocation,
          date: date,
          timings: { from: timeFrom, to: timeTo },
          contactForm: contactForm,
          formSubmissionNote: formSubmissionNote,
          formSelection: formSelection,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      console.log("data", data);
      if (data.status === true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleBlog = async () => {
    if (!title) {
      return toast.error("Please Enter Title");
    }

    try {
      const { data } = await axios.post(
        `${url}/api/v1/blog/add`,
        {
          lan: activeLanguage,
          title: title,
          image: image,
          description: description,
          metatitle: metatitle,
          metadescription: metadescription,
          metakeywords: metakeywords,
          slug: slug,
          cat: selectedCategory,
          tag: selectedTag,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      if (data.status === true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (id) {
      const getStepData = async () => {
        const { data } = await axios.get(
          type === "event"
            ? `${url}/api/v1/event/get/${id}`
            : `${url}/api/v1/blog/get/${id}`,
          {
            headers: {
              token: `${token}`,
            },
          }
        );
        console.log("data", data);
        if (activeLanguage === "ar") {
          setDescription(data.data.ar_description);
          setTitle(data.data.ar_title);
          setImage(data.data.ar_image);
          setMetatitle(data.data.ar_metatitle);
          setMetakeywords(data.data.ar_metakeywords);
          setMetadescription(data.data.ar_metadescription);
          setSlug(data.data.ar_slug);
          if (type === "event") {
            setEventLocation(data.data.ar_location);
            setDate(data.data.ar_date);
            setTimeFrom(data.data.ar_timings?.from);
            setTimeTo(data.data.ar_timings?.to);
            setContactForm(data.data.ar_contact_form);
            setFormSubmissionNote(data.data.ar_formSubmissionNote);
            setFormSelection(data.data.ar_formSelected);
          } else {
            setSelectedCategory(data.data.ar_category);
            setSelectedTags(data.data.ar_tag);
          }
        } else {
          setDescription(data.data.en_description);
          setTitle(data.data.en_title);
          setImage(data.data.en_image);
          setMetatitle(data.data.en_metatitle);
          setMetakeywords(data.data.en_metakeywords);
          setMetadescription(data.data.en_metadescription);
          setSlug(data.data.en_slug);
          if (type === "event") {
            setEventLocation(data.data.en_location);
            setDate(data.data.en_date);
            setTimeFrom(data.data.en_timings?.from);
            setTimeTo(data.data.en_timings?.to);
            setContactForm(data.data.en_contact_form);
            setFormSubmissionNote(data.data.en_formSubmissionNote);
            setFormSelection(data.data.en_formSelected);
          } else {
            setSelectedCategory(data.data.en_category);
            setSelectedTags(data.data.en_tag);
          }
        }

        console.log("formslsect => ", formSelection);
      };
      getStepData();
    } else {
      setDescription("");
      setTitle("");
      setImage("");
      setMetatitle("");
      setMetakeywords("");
      setMetadescription("");
      setSlug("");
      setSelectedCategory([]);
      setSelectedTags([]);
      setFormSubmissionNote("");
      setFormSelection();
    }
  }, [id, activeLanguage]);

  const handleStepUpdate = async () => {
    try {
      const { data } = await axios.put(
        `${url}/api/v1/blog/update/${id}`,
        {
          lan: activeLanguage,
          title: title,
          image: image,
          description: description,
          metatitle: metatitle,
          metadescription: metadescription,
          metakeywords: metakeywords,
          slug: slug,
          cat: selectedCategory,
          tag: selectedTag,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      if (data.status === true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleUpdateEvent = async () => {
    try {
      const { data } = await axios.put(
        `${url}/api/v1/event/update/${id}`,
        {
          lan: activeLanguage,
          title: title,
          image: image,
          description: description,
          metatitle: metatitle,
          metadescription: metadescription,
          metakeywords: metakeywords,
          slug: slug,
          location: eventLocation,
          date: date,
          timings: { from: timeFrom, to: timeTo },
          contactForm: contactForm,
          formSubmissionNote: formSubmissionNote,
          formSelection: formSelection,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      if (data.status === true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleTitle = (e) => {
    setTitle(e.target.value);
    let stringWithoutSpaces = e.target.value.replace(/\s+/g, "-");
    setSlug(stringWithoutSpaces.toLowerCase());
  };

  const handleCategoryChange = (categoryId) => {
    const isSelected = selectedCategory.includes(categoryId);
    if (isSelected) {
      setSelectedCategory(selectedCategory.filter((id) => id !== categoryId));
    } else {
      setSelectedCategory([...selectedCategory, categoryId]);
    }
  };

  const handleTagChange = (tagId) => {
    const isSelected = selectedTag.includes(tagId);
    if (isSelected) {
      setSelectedTags(selectedTag.filter((id) => id !== tagId));
    } else {
      setSelectedTags([...selectedTag, tagId]);
    }
  };

  const getCategory = async () => {
    const { data } = await axios.get(`${url}/api/v1/blog/category/get/`, {
      headers: {
        token: `${token}`,
      },
    });
    setCategory(data.data);
  };
  const getTag = async () => {
    const { data } = await axios.get(`${url}/api/v1/blog/tag/get/`, {
      headers: {
        token: `${token}`,
      },
    });
    setTag(data.data);
  };

  useEffect(() => {
    getCategory();
    getTag();
  }, []);

  const handleAddEvent = () => {
    if (featureName === "") {
      return toast.error("Please enter a feature name to add.");
    }
    setFeatures([featureName, ...features]);
    setFeatureName("");
  };

  return (
    <>
      <div className="col-lg-8">
        <div className="card mb-3">
          <ToastContainer />

          <div className="card-header pt-2">
            {type === "event" ? (
              <h4>{id ? `Edit Event ${title}` : "Add Event"}</h4>
            ) : (
              <h4>{id ? `Edit Blog ${title}` : "Add Blog"}</h4>
            )}

            <ul className="nav nav-tabs card-header-tabs" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activeLanguage === "en" ? "active" : ""
                  }`}
                  onClick={() => changeLanguage("en")}
                >
                  English
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activeLanguage === "ar" ? "active" : ""
                  }`}
                  onClick={() => changeLanguage("ar")}
                >
                  Arabic
                </button>
              </li>
            </ul>
          </div>

          <div className="tab-content">
            <div className="tab-pane fade active show" role="tabpanel">
              <div>
                <div className="row g-3">
                  <div className="col-md-12">
                    <label className="form-label">{t("Title ")}</label>
                    <input
                      type="text"
                      dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                      className="form-control"
                      name="name"
                      value={title}
                      onChange={handleTitle}
                    />
                  </div>

                  <div className="col-md-12">
                    <label className="form-label">{t("Description ")}</label>
                    <CKEditor
                      key={`editor1-${activeLanguage}`}
                      config={{
                        language: {
                          ui: activeLanguage === "ar" ? "ar" : "en", // UI language
                          content: activeLanguage === "ar" ? "ar" : "en", // Content language
                        },
                        contentsLangDirection:
                          activeLanguage === "ar" ? "rtl" : "ltr", // Content direction
                          toolbar: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "|",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "|",
                            "indent",
                            "outdent",
                            "|",
                            "imageUpload",
                            "blockQuote",
                            "insertTable",
                            "mediaEmbed",
                            "|",
                            "undo",
                            "redo",
                            "|",
                            "selectAll",
                          ],
                          heading: {
                            options: [
                              {
                                model: "paragraph",
                                title: "Paragraph",
                                class: "ck-heading_paragraph",
                              },
                              {
                                model: "heading1",
                                view: "h1",
                                title: "Heading 1",
                                class: "ck-heading_heading1",
                              },
                              {
                                model: "heading2",
                                view: "h2",
                                title: "Heading 2",
                                class: "ck-heading_heading2",
                              },
                              {
                                model: "heading3",
                                view: "h3",
                                title: "Heading 3",
                                class: "ck-heading_heading3",
                              },
                              {
                                model: "heading4",
                                view: "h4",
                                title: "Heading 4",
                                class: "ck-heading_heading4",
                              },
                              {
                                model: "heading5",
                                view: "h5",
                                title: "Heading 5",
                                class: "ck-heading_heading5",
                              },
                              {
                                model: "heading6",
                                view: "h6",
                                title: "Heading 6",
                                class: "ck-heading_heading6",
                              },
                            ],
                          },
                      }}
                      editor={ClassicEditor}
                      data={description}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setDescription(data);
                      }}
                    />
                  </div>

                  <div className="col-md-12">
                    <label className="form-label">Meta Title</label>
                    <input
                      type="text"
                      dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                      className="form-control"
                      name="name"
                      value={metatitle}
                      onChange={(e) => setMetatitle(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">Meta Description</label>
                    <input
                      type="text"
                      dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                      className="form-control"
                      name="name"
                      value={metadescription}
                      onChange={(e) => setMetadescription(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">Meta Keywords</label>
                    <input
                      type="text"
                      dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                      className="form-control"
                      name="name"
                      value={metakeywords}
                      onChange={(e) => setMetakeywords(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label">Slug </label>
                    <input
                      type="text"
                      dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                      className="form-control"
                      name="name"
                      value={slug}
                      onChange={(e) => setSlug(e.target.value)}
                    />
                  </div>
                  {type === "event" && (
                    <div
                      className="col-md-12"
                      style={{ display: "flex", gap: "2%" }}
                    >
                      <label className="form-label">
                        {t("Contact Form Visible  ")}&nbsp;&nbsp;-
                      </label>
                      <label className="switch">
                        <input
                          type="checkbox"
                          className="switch-input"
                          checked={contactForm}
                          onChange={() => {
                            setContactForm(!contactForm);
                          }}
                        />
                        <span className="switch-toggle-slider">
                          {contactForm ? (
                            <span className="switch-on">
                              <i className="ti ti-check"></i>
                            </span>
                          ) : (
                            <span className="switch-off">
                              <i className="ti ti-x"></i>
                            </span>
                          )}
                        </span>
                        <span className="switch-label">
                          {contactForm ? "Visible" : "hidden"}
                        </span>
                      </label>
                    </div>
                  )}

                  {type === "event" && (
                    <>
                      <div className="col-md-12">
                        <label className="form-label">
                          {t("Contact Form Submission Note")}
                        </label>
                        <CKEditor
                          key={`editor2-${activeLanguage}`}
                          config={{
                            language: {
                              ui: activeLanguage === "ar" ? "ar" : "en", // UI language
                              content: activeLanguage === "ar" ? "ar" : "en", // Content language
                            },
                            contentsLangDirection:
                              activeLanguage === "ar" ? "rtl" : "ltr", // Content direction
                              toolbar: [
                                "heading",
                                "|",
                                "bold",
                                "italic",
                                "|",
                                "link",
                                "bulletedList",
                                "numberedList",
                                "|",
                                "indent",
                                "outdent",
                                "|",
                                "imageUpload",
                                "blockQuote",
                                "insertTable",
                                "mediaEmbed",
                                "|",
                                "undo",
                                "redo",
                                "|",
                                "selectAll",
                              ],
                              heading: {
                                options: [
                                  {
                                    model: "paragraph",
                                    title: "Paragraph",
                                    class: "ck-heading_paragraph",
                                  },
                                  {
                                    model: "heading1",
                                    view: "h1",
                                    title: "Heading 1",
                                    class: "ck-heading_heading1",
                                  },
                                  {
                                    model: "heading2",
                                    view: "h2",
                                    title: "Heading 2",
                                    class: "ck-heading_heading2",
                                  },
                                  {
                                    model: "heading3",
                                    view: "h3",
                                    title: "Heading 3",
                                    class: "ck-heading_heading3",
                                  },
                                  {
                                    model: "heading4",
                                    view: "h4",
                                    title: "Heading 4",
                                    class: "ck-heading_heading4",
                                  },
                                  {
                                    model: "heading5",
                                    view: "h5",
                                    title: "Heading 5",
                                    class: "ck-heading_heading5",
                                  },
                                  {
                                    model: "heading6",
                                    view: "h6",
                                    title: "Heading 6",
                                    class: "ck-heading_heading6",
                                  },
                                ],
                              },
                          }}
                          editor={ClassicEditor}
                          data={formSubmissionNote}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setFormSubmissionNote(data);
                          }}
                        />
                       
                      </div>
                      <div className="col-md-12">
                        <label className="form-label">
                          Contact Form Selection
                        </label>
                        <select
                          dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                          name="formSelection"
                          className="form-select"
                          onChange={(e) => setFormSelection(e.target.value)}
                        >
                          <option value="">Select a Form</option>
                          {formSelections.map((forms) => (
                            <option
                              key={forms.id}
                              value={forms.id}
                              selected={formSelection == forms.id}
                            >
                              {forms.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </>
                  )}
                </div>

                <div className="pt-4">
                  {id ? (
                    <button
                      type="submit"
                      className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                      onClick={() =>
                        type === "event"
                          ? handleUpdateEvent()
                          : handleStepUpdate()
                      }
                    >
                      {t("Update")}
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                      onClick={
                        type === "event" ? handleSubmitEventData : handleBlog
                      }
                    >
                      {t("Submit")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card mb-3">
          <div className="card-body">
            <div className="row g-3">
              {type === "even2t" && (
                <>
                  <label className="form-label">
                    {t("Add Event Features")}
                  </label>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <input
                        type="text"
                        placeholder="Enter feature name"
                        dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                        className="form-control"
                        name="name"
                        value={featureName}
                        onChange={(e) => setFeatureName(e.target.value)}
                      />
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                        onClick={handleAddEvent}
                      >
                        {t("Add")}
                      </button>
                    </div>
                  </div>
                </>
              )}
              {type !== "event" && (
                <>
                  <div className="col-md-12 border p-3">
                    <>
                      <label className="form-label">
                        {t("All Categories")}
                      </label>
                      <ul
                        className="cats-list"
                        style={{ listStyle: "none", padding: "0px" }}
                      >
                        {category.map((item) => (
                          <li key={category._id} className="me-2">
                            <input
                              className="me-2"
                              type="checkbox"
                              id={item._id}
                              value={item._id}
                              checked={selectedCategory.includes(item._id)}
                              onChange={() => handleCategoryChange(item._id)}
                            />
                            <label htmlFor={item._id}>
                              {activeLanguage === "ar"
                                ? item.ar_title
                                : item.en_title}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </>
                  </div>
                </>
              )}

              <div className="col-md-12">
                <label className="form-label">{t("Image")}</label>

                <div {...getRootProps()} style={dropzoneStyles}>
                  <input {...getInputProps()} />
                  <p>
                    {t(
                      "Drag and drop an image file here, or click to select one"
                    )}
                  </p>
                </div>
                {image && image.path}
                <div
                  className=""
                  style={{ textAlign: "center", marginTop: "15px" }}
                >
                  {image && (
                    <img src={`${Image}/${image}`} width="200px" alt="Blog" />
                  )}
                </div>
              </div>
              {type !== "event" && (
                <div className="col-md-12 border p-3">
                  <label className="form-label">{t("All Tags")}</label>
                  <ul
                    className="cats-list"
                    style={{ listStyle: "none", padding: "0px" }}
                  >
                    {tag.map((item) => (
                      <li key={item._id} className="me-2">
                        <input
                          className="me-2"
                          type="checkbox"
                          id={item._id}
                          value={item._id}
                          checked={selectedTag.includes(item._id)}
                          onChange={() => handleTagChange(item._id)}
                        />
                        <label htmlFor={item._id}>
                          {activeLanguage === "ar"
                            ? item.ar_title
                            : item.en_title}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default AddBlogForm;
