import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { useSelector } from "react-redux";
import axios from "axios";
import { url, Image } from "../../Components/Common/Url";
import { ToastContainer, toast } from "react-toastify";

import { Link, useNavigate, useParams } from "react-router-dom";

const FooterForm = () => {
  const params = useParams();
  const { id } = params;

  const { token } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [category, setCategory] = useState("");

  const navigate = useNavigate();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  const handleStep = async () => {
    if (!title) {
      return toast.error("Please Enter Title");
    }
    const cat = document.querySelector("#category").value;
    //alert(cat)
    if (!cat) {
      return toast.error("Please Select Footer Type");
    }
    const formData = new FormData();
    formData.append("lan", activeLanguage);
    formData.append("title", title);

    formData.append("link", link);
    formData.append("category", cat);
    console.log(formData);

    try {
      const { data } = await axios.post(`${url}/api/v1/footer/add`, formData, {
        headers: {
          "Content-Type": "application/json",
          token: `${token}`,
        },
      });

      toast.success(data.message);
      if (data.status == true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (id) {
      const get_step_data = async () => {
        const { data } = await axios.get(`${url}/api/v1/footer/get/${id}`, {
          headers: {
            token: `${token}`,
          },
        });
        console.log(data);
        if (activeLanguage === "ar") {
          setLink(data.data.ar_link);
          setTitle(data.data.ar_title);
          setCategory(data.data.en_type);
        } else {
          setLink(data.data.en_link);
          setTitle(data.data.en_title);
          setCategory(data.data.en_type);
        }
      };
      get_step_data();
    } else {
      setLink("");
      setTitle("");
    }
  }, [id, activeLanguage]);

  const handleStepUpdate = async () => {
    const cat = document.querySelector("#category").value;
    if (cat == null) {
      return toast.error("Please Select Footer Type");
    }
    const formData = new FormData();
    formData.append("lan", activeLanguage);
    formData.append("title", title);

    formData.append("link", link);
    formData.append("category", cat);

    try {
      const { data } = await axios.put(
        `${url}/api/v1/footer/update/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      if (data.status == true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const cancelAction = async (id) => {
    navigate(`/admin/header`);
  };

  const cats = [
    {
      name: "Footer One",
      id: 1,
      arname: "تذييل واحد",
    },
    {
      name: "Footer Two",
      id: 2,
      arname: "التذييل الثاني",
    },
    {
      name: "Footer Three",
      id: 3,
      arname: "التذييل الثالث",
    },
  ];

  return (
    <div className="col-md-4">
      <ToastContainer />
      <div className="card mb-3">
        <div className="card-header pt-2">
          <h4>Footer Menu</h4>
          <ul className="nav nav-tabs card-header-tabs" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeLanguage === "en" ? "active" : ""
                }`}
                onClick={() => changeLanguage("en")}
              >
                English
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activeLanguage === "ar" ? "active" : ""
                }`}
                onClick={() => changeLanguage("ar")}
              >
                Arabic
              </button>
            </li>
          </ul>
        </div>

        <div className="tab-content">
          <div className="tab-pane fade active show" role="tabpanel">
            <div>
              <div className="row g-3">
                <div className="col-md-12">
                  <label className="form-label">{t("Title")}</label>
                  <input
                    type="text"
                    dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                    className="form-control"
                    name="name"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="col-md-12">
                  <label className="form-label">{t("url")}</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                  />
                </div>
                <div className="col-md-12">
                  <label className="form-label">{t("footer")}</label>
                  <select
                    dir={activeLanguage == "ar" ? "rtl" : "ltr"}
                    className="form-control"
                    id="category"
                  >
                    {cats.map((item) => {
                      return (
                        <>
                          {category == item.name ? (
                            <option key={item.id} selected={true}>
                              {i18n.language == "ar" ? item.arname : item.name}
                            </option>
                          ) : (
                            <option key={item.id}>
                              {i18n.language == "ar" ? item.arname : item.name}
                            </option>
                          )}
                        </>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div class="pt-4">
                {id ? (
                  <button
                    type="submit"
                    class="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                    onClick={handleStepUpdate}
                  >
                    {t("Update")}
                  </button>
                ) : (
                  <button
                    type="submit"
                    class="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                    onClick={handleStep}
                  >
                    {t("Submit")}
                  </button>
                )}

                {id ? (
                  <button
                    type="button"
                    className="btn btn-warning me-sm-3 me-1 waves-effect waves-light"
                    onClick={cancelAction}
                  >
                    {t("Cancel")}
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default FooterForm;
