import React from 'react'
import InsuranceForm from '../../Forms/PatientCare/InsuranceForm'
import InsuranceListComponent from '../../Components/PatientCare/InsuranceListComponent'


export default function InsurancePage() {
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
         <InsuranceForm />
         <InsuranceListComponent />
      </div>
    </div>
  )
}
