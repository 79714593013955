
// export const url = 'http://localhost:5500';
// export const assetUrl = 'http://localhost:5500'
// export const Image = 'http://localhost:5500/uploads/'
// export const BASE_URL = 'http://localhost:5500/'



export const url = 'https://asteradmin.stldigitaltech.com:3000';
export const assetUrl = 'https://asteradmin.stldigitaltech.com:3000'
export const Image = 'https://asteradmin.stldigitaltech.com:3000/uploads'
export const BASE_URL = 'https://asteradmin.stldigitaltech.com:3000/'
