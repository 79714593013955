import React from 'react'
import SeniorCareForm from '../../Forms/PatientCare/SeniorCareForm'

export default function SeniorCarePage() {
  return (
    <div>
        <SeniorCareForm />
    </div>
  )
}
