import React from 'react'
import PreventPackageForm from '../../Forms/PatientCare/PreventPackageForm'
import PreventHealthPackageListComponent from '../../Components/PatientCare/PreventHealthPackageListComponent'


export default function PreventHealthPage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row g-3">
               <PreventPackageForm />
               <PreventHealthPackageListComponent/>
            </div>
        </div>
    )
}
