import React, { useState, useEffect } from "react";
import { Navigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PasswordField from '../Components/Fields/PasswordField';
import { updateProfile, updatePassword } from '../Services/UserSlices';
import { toast } from 'react-toastify';
import { assetUrl } from '../Components/Common/Url'


const Profile = () => {

    const dispatch = useDispatch();

    const { token, user: currentUser } = useSelector((state) => state.user);

    if (!currentUser) {
        return <Navigate to="/" />;
    }

    const initialValues = {
        name: currentUser?.name,
        email: currentUser?.email,
        profilePicture: null,
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        profilePicture: Yup.mixed()
            .nullable()
            .test(
                'fileSize',
                'File size is too large',
                value => !value || (value && value.size <= 800000)
            ),
    });


    const changePwdValidationSchema = Yup.object().shape({
        cpassword: Yup.string()
            .required("Please enter your current password"),
        npassword: Yup.string()
            .required("Please enter new password").test(
                "len",
                "The password must be between 8 and 40 characters.",
                (val) =>
                    val &&
                    val.toString().length >= 6 &&
                    val.toString().length <= 40
            ),
        vpassword: Yup.string()
            .required("Please retype your password.")
            .oneOf([Yup.ref('npassword'), null], 'Your passwords do not match.'),
    });


    const handleSubmitProfileUpdate = async (formData, actions) => {
        toast.dismiss();
        try {
            const resultAction = await dispatch(updateProfile(formData));
            if (updateProfile.fulfilled.match(resultAction)) {
                toast.success(resultAction.payload.message);
                
            } else {
                toast.error(resultAction.payload || 'Failed to update profile');
            }
        }
        catch (error) {
            actions.setSubmitting(false);
            toast.error('Failed to update profile');
        } finally {
            actions.setSubmitting(false);
        }
    };

    const handleSubmitPasswordUpdate = async (passwords, actions) => {
        toast.dismiss();
        try {
            const resultAction = await dispatch(updatePassword(passwords));
            if (updatePassword.fulfilled.match(resultAction)) {
                toast.success(resultAction.payload);
                actions.resetForm();
            } else {
                toast.error(resultAction.payload || 'Failed to update password');
            }
        }
        catch (error) {
            actions.setSubmitting(false);
            toast.error('Failed to update password');
        } finally {
            actions.setSubmitting(false);
        }
    };


    const handleFileChange = (event, setFieldValue) => {
        const file = event.currentTarget.files[0];
        setFieldValue('profilePicture', file);
    };


    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-xl">
                    <div className="card mb-4">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h5 className="mb-0">Profile Update</h5>
                        </div>
                        <div className="card-body">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmitProfileUpdate}
                            >
                                {({ isSubmitting, setFieldValue }) => (
                                    <Form>
                                        <div className="d-flex align-items-start align-items-sm-center gap-4 mb-3">
                                            <img
                                                src={currentUser?.profilePicture ? `${assetUrl}/${currentUser.profilePicture}` : "../../assets/img/avatars/14.png"}
                                                alt="user-avatar"
                                                className="d-block w-px-100 h-px-100 rounded"
                                                id="uploadedAvatar"
                                            />
                                            <div className="button-wrapper">
                                                <label
                                                    htmlFor="upload"
                                                    className="btn btn-primary me-2 mb-3 waves-effect waves-light"
                                                    tabIndex="0"
                                                >
                                                    <span className="d-none d-sm-block">Upload new photo</span>
                                                    <i className="ti ti-upload d-block d-sm-none"></i>
                                                    <input
                                                        type="file"
                                                        id="upload"
                                                        className="account-file-input d-none"
                                                        accept="image/png, image/jpeg"
                                                        onChange={(e) => handleFileChange(e, setFieldValue)}
                                                    />
                                                </label>
                                                <div className="text-muted">Allowed JPG, GIF or PNG. Max size of 800K</div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="basic-default-fullname">
                                                Name
                                            </label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                id="basic-default-fullname"
                                                name="name"
                                            />
                                            <ErrorMessage name="name" component="div" className="text-danger" />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="basic-default-email">
                                                Email
                                            </label>
                                            <Field
                                                type="text"
                                                className="form-control"
                                                id="basic-default-email"
                                                name="email"
                                                disabled
                                            />
                                            <div className="form-text">Can't Edit Your Email</div>
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn btn-primary waves-effect waves-light"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting ? 'Updating...' : 'Update'}
                                        </button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>

                    <div className="card mb-4">
                        <h5 className="card-header">Change Password</h5>
                        <div className="card-body">
                            <Formik
                                initialValues={{
                                    cpassword: '',
                                    npassword: '',
                                    vpassword: '',
                                }}
                                validationSchema={changePwdValidationSchema}
                                onSubmit={handleSubmitPasswordUpdate}
                            >
                                {({ isSubmitting }) => (
                                    <Form id="formAccountSettings">
                                        <div className="row">
                                            <PasswordField fieldName="cpassword" />
                                        </div>
                                        <div className="row">
                                            <PasswordField fieldName="npassword" />
                                            <PasswordField fieldName="vpassword" />
                                            <div className="col-12 mb-4">
                                            </div>
                                            <div>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary me-2 waves-effect waves-light"
                                                    disabled={isSubmitting}
                                                >
                                                    Save changes
                                                </button>
                                                <button type="reset" className="btn btn-label-secondary waves-effect">
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
