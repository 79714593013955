import React from 'react'
import OffShoresForm from '../../Forms/PatientCare/OffShoresForm'

export default function OffShoresPage() {
  return (
    <div>
      <OffShoresForm />
    </div>
  )
}
