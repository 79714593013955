import React from "react";
import SliderForm from "../../Forms/AsterAtHome/SliderForm";
import SliderListComponent from "../../Components/AsterAtHome/SliderListComponent";

export default function Slider() {
  return (
    <>
      <SliderForm />
      <SliderListComponent />
    </>
  );
}
