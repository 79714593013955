import React from 'react'
import SpecialClinicTypeForm from '../../Forms/PatientCare/SpecialClinicTypeForm'
import SpecialClinicListComponent from '../../Components/PatientCare/SpecialClinicTypeListComponent'

export default function SpecialClinicTypePage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row g-3">
                <SpecialClinicTypeForm />
                <SpecialClinicListComponent />

            </div>
        </div>
    )
}
