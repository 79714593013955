import React from 'react'
import SeniorCareOfferForm from '../../Forms/PatientCare/SeniorCareOfferForm'
import SeniorCareOfferListComponent from '../../Components/PatientCare/SeniorCareOfferListComponent'


export default function SeniorCareOfferPage() {
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
       <SeniorCareOfferForm />
       <SeniorCareOfferListComponent />
      </div>
    </div>
  )
}
