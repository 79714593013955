import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import EnEditDoctorForm from '../../Forms/EnEditDoctorForm';
import ArEditDoctorForm from '../../Forms/ArEditDoctorForm';

export default function Doctor() {
  const [key, setKey] = useState('english');
  const { id } = useParams();

  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">

        <div className="row">
          <div className="col">

            <div className="card mb-3">
              <h5 className="card-header">Edit Doctor </h5>
              <div className="card-header pt-2">
                <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="english">English</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="arabic">Arabic</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="english">
                      <EnEditDoctorForm id={id} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="arabic">
                      <ArEditDoctorForm id={id} />
                    </Tab.Pane>

                  </Tab.Content>

                </Tab.Container>
              </div>





            </div>
          </div>
        </div>
      </div>
    </>

  )
}
