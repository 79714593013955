import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const PdfUpload = ({pdf , setPdf , setError}) => {
    const handleDrop = useCallback(acceptedFiles => {
        // Assuming you only want to handle one file at a time
        const file = acceptedFiles[0];
        if (file.type === 'application/pdf') {
            // Do something with the valid PDF file
            console.log('Valid PDF file:', file);
            setPdf(file)
        } else {
            // Handle invalid file type
            setError('Invalid file type. Please upload a PDF file.')
            console.error('Invalid file type. Please upload a PDF file.');
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: '.pdf',
        onDrop: handleDrop
    });

    return (
        <div {...getRootProps()} style={dropzoneStyles}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop a PDF file here, or click to select one</p>
        </div>
    );
};

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
};

export default PdfUpload;
