import React from "react";
// import ListOffers from "./ListOffers";
import { useLocation } from "react-router-dom";
import ListNews from "./ListNews";
export default function AllNews() {
  const location = useLocation();
  const data = location.state.type || {};
  console.log(data);
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="col">
          <ListNews type={data} />
        </div>
      </div>
    </div>
  );
}
