import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Image, url } from '../Common/Url'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

export default function SliderListComponent() {
    const { token } = useSelector((state) => state.user)
    const navigate = useNavigate()

    const [sliders, setSliders] = useState([])
    const get_slider = async () => {
        try {
            const { data } = await axios.get(`${url}/api/v1/wellnessslider/get/`, {
                headers: {
                    "token": `${token}`
                }
            })

            console.log(data)
            setSliders(data.data)
        } catch (error) {
            toast.error(error)
        }
    }
    useEffect(() => {
        get_slider()
    }, [])


    const handleStatus = async (id, s) => {
        try {
            let status = 0;
            if (s == 1) {
                status = 0
            } else {
                status = 1;
            }
            const formData = new FormData();
            formData.append('status', status);

            const { data } = await axios.post(`${url}/api/v1/wellnessslider/status/change/${id}`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    "token": `${token}`,

                }
            })

            console.log(data)
            toast.success(data.message)
            get_slider()

        } catch (error) {
            toast.error(error)
        }

    }


    const handledelete = async (id) => {
        try {


            const { data } = await axios.delete(`${url}/api/v1/wellnessslider/delete/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    "token": `${token}`,

                }
            })
            console.log(data)
            toast.success(data.message)
            get_slider()

        } catch (error) {
            toast.error(error)
        }
    }


    const handleEdit = async (id) => {
        navigate(`/admin/wellness/slider/${id}`)
    }



    return (
        
        <div class="col-xl-8 mb-3 mb-xl-0">
        <div class="card">
        <ToastContainer />
            <div class="card-body">
                <h5>Sliders</h5>
                <ul class="list-group mb-3">
                    {
                        sliders.length > 0 ? <>
                         {
                        sliders.map((item, i) => {
                            return (
                                <li class="list-group-item p-4" key={i}>
                                    <div class="d-flex gap-3">

                                        <div class="flex-grow-1">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <img src={`${Image}/${item.en_slider}`} alt="google home" width={"200px"} />

                                                </div>
                                                <div class="col-md-6">
                                                    <div class="text-md-end">
                                                        <div class="d-flex">
                                                            <span>
                                                                <label class="switch">
                                                                    <input type="checkbox" class="switch-input" checked={item.status == 1 ? 'true' : ''} onClick={() => handleStatus(item._id, item.status)} />
                                                                    <span class="switch-toggle-slider">
                                                                        {
                                                                            item.status == 1 ? <span class="switch-on" onClick={() => handleStatus(item._id, item.status)} style={{ cursor: "pointer" }}>
                                                                                <i class="ti ti-check"></i>
                                                                            </span> : <span class="switch-off" onClick={() => handleStatus(item._id, item.status)} style={{ cursor: "pointer" }}>
                                                                                <i class="ti ti-x"></i>
                                                                            </span>
                                                                        }


                                                                    </span>
                                                                    <span class="switch-label">{item.status == 1 ? 'Publish' : 'Draft'}</span>
                                                                </label>
                                                            </span>
                                                            <span type="button" class="btn btn-xs btn-success waves-effect waves-light" onClick={() => handleEdit(item._id)}>
                                                                Edit
                                                            </span>
                                                            <span type="button" class="btn btn-xs btn-danger waves-effect waves-light" onClick={() => handledelete(item._id)}>
                                                                Delete
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            )
                        })
                    }
                         </> : <>
                          <p>No data found...</p>
                          </>
                    }
                    


                </ul>
            </div>
        </div>


    </div>

    )
}
