import React from 'react'
import TestimonialForm from '../../Forms/Wellness/TestimonialForm'
import TestimonialListComponent from '../../Components/Wellness/TestimonialListComponent'

export default function TestimonialPage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <TestimonialForm />
                <TestimonialListComponent />
            </div>
        </div>
    )
}
