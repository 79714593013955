import React from 'react'
import CounterForm from '../../Forms/SkinCare/CounterForm'
import CounterListComponent from '../../Components/Skincare/CounterListComponent'

export default function CounterPage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">

            <div className="row">
                <CounterForm />
                <CounterListComponent />
            </div>
        </div>
    )
}
