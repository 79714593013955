import React from 'react'
import PhilosopyForm from '../../Forms/PatientCare/PhiosopyForm'
import PhilosopyListComponent from '../../Components/PatientCare/PhilosopyListComponent'

export default function PhilosopyPage() {
  return (
    <div><PhilosopyForm />
     <PhilosopyListComponent />
    </div>
  )
}
