import React from 'react'
import VitaminWorkForm from '../../Forms/Vitamin/VitaminWorkFrom'
import VitaminWorkListComponent from '../../Components/Vitamin/VitaminWorkListComponent'


export default function VitaminWorkTherapy() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <VitaminWorkForm />
                <VitaminWorkListComponent />
              
            </div>
        </div>
    )
}
