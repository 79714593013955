import React from 'react';

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div>
    <input
      id="search"
      type="text"
      placeholder="Search by title"
      value={filterText}
      onChange={onFilter}
      className='form-control'
    />
    {/* <button onClick={onClear}>Clear</button> */}
  </div>
);

export default FilterComponent;
