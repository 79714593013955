import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { url } from "../Common/Url";
import { useSelector } from "react-redux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

export default function ValueList() {
  const [data, setData] = useState([]);
  const [entextEditor, setEnTextEditor] = useState("");
  const [artextEditor, setArTextEditor] = useState("");
  const [id, setId] = useState("");

  const [entitle, setEnTitle] = useState("");
  const [artitle, setArTitle] = useState("");
  const [show, setShow] = useState(false);
  const [seoTitleEn, setSeoTitleEn] = useState("");
  const [seoKeywordsEn, setSeoKeywordsEn] = useState("");
  const [seoDescEn, setSeoDescEn] = useState("");
  const [seoTitleAr, setSeoTitleAr] = useState("");
  const [seoKeywordsAr, setSeoKeywordsAr] = useState("");
  const [seoDescAr, setSeoDescAr] = useState("");

  const handleClose = () => setShow(false);

  const [file, setFile] = useState(null);

  const { token } = useSelector((state) => state.user);
  const get_executive_member = async () => {
    const response = await fetch(`${url}/api/v1/user/value`, {
      method: "get",
      headers: {
        token: `${token}`,
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    console.log(result);
    setData(result.data);
  };

  const handleDelete = async (id) => {
    try {
      const { data } = await axios.delete(`${url}/api/v1/user/value/${id}`, {
        headers: {
          token: `${token}`,
          "Content-Type": "application/json",
        },
      });

      toast(data.message);

      if (data.status == true) {
        setInterval(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (error) {
      toast(error);
    }
  };

  const handleEdit = async (id) => {
    const { data } = await axios.get(`${url}/api/v1/user/value/${id}`, {
      headers: {
        token: `${token}`,
        "Content-Type": "application/json",
      },
    });
    console.log(data);
    setId(id);
    setFile(data.data.image);
    setEnTextEditor(data.data.en_description);
    setEnTitle(data.data.en_title);
    setArTextEditor(data.data.ar_description);
    setArTitle(data.data.ar_title);
    setSeoKeywordsEn(data.data.seoKeywordsEn);
    setSeoDescEn(data.data.seoDescEn);
    setSeoTitleEn(data.data.seoTitleEn);
    setSeoKeywordsAr(data.data.seoKeywordsAr);
    setSeoDescAr(data.data.seoDescAr);
    setSeoTitleAr(data.data.seoTitleAr);
    setShow(true);
  };

  const handleUpdate = async () => {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("entextEditor", entextEditor);
    formData.append("artextEditor", artextEditor);
    formData.append("entitle", entitle);
    formData.append("artitle", artitle);

    formData.append("seoTitleEn", seoTitleEn);
    formData.append("seoKeywordsEn", seoKeywordsEn);
    formData.append("seoDescEn", seoDescEn);
    formData.append("seoTitleAr", seoTitleAr);
    formData.append("seoKeywordsAr", seoKeywordsAr);
    formData.append("seoDescAr", seoDescAr);
    const { data } = await axios.put(
      `${url}/api/v1/user/value/${id}`,
      formData,
      {
        headers: {
          token: `${token}`,
        },
      }
    );
    if (data.status == true) {
      toast(data.message);
      window.location.reload();
    }
  };

  useEffect(() => {
    get_executive_member();
  }, []);

  return (
    <>
      <ToastContainer />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Title</th>
            <th>Image</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, it) => {
            return (
              <tr key={it}>
                <td>{item.en_title}</td>
                <td>
                  <img src={`${url}/uploads/${item.image}`} width="50px" />
                </td>
                <td>
                  <div class="">
                    <button
                      class="btn btn-info"
                      onClick={() => handleEdit(item._id)}
                    >
                      <i class="ti ti-pencil me-1"></i>
                    </button>
                    <button
                      class="btn btn-danger"
                      onClick={() => handleDelete(item._id)}
                    >
                      <i class="ti ti-trash me-1"></i>
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Executive Memeber #</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row g-3">
            <div class="col-sm-12">
              <label class="form-label" for="username">
                <b>Image</b>
              </label>
              <input
                type="file"
                id="username"
                class="form-control"
                filename={file}
                onChange={(e) => setFile(e.target.files[0])}
                accept="image/*"
              />
            </div>
            <div class="col-sm-12">
              <label class="form-label" for="username">
                <b>Title (En)</b>
              </label>
              <input
                type="text"
                id="username"
                class="form-control"
                value={entitle}
                onChange={(e) => setEnTitle(e.target.value)}
              />
            </div>
            <div class="col-sm-12">
              <label class="form-label" for="username">
                <b>Description (En) </b>
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={entextEditor}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();

                  setEnTextEditor(data);
                }}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "|",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "indent",
                    "outdent",
                    "|",
                    "imageUpload",
                    "blockQuote",
                    "insertTable",
                    "mediaEmbed",
                    "|",
                    "undo",
                    "redo",
                    "|",
                    "selectAll",
                  ],
                  heading: {
                    options: [
                      {
                        model: "paragraph",
                        title: "Paragraph",
                        class: "ck-heading_paragraph",
                      },
                      {
                        model: "heading1",
                        view: "h1",
                        title: "Heading 1",
                        class: "ck-heading_heading1",
                      },
                      {
                        model: "heading2",
                        view: "h2",
                        title: "Heading 2",
                        class: "ck-heading_heading2",
                      },
                      {
                        model: "heading3",
                        view: "h3",
                        title: "Heading 3",
                        class: "ck-heading_heading3",
                      },
                      {
                        model: "heading4",
                        view: "h4",
                        title: "Heading 4",
                        class: "ck-heading_heading4",
                      },
                      {
                        model: "heading5",
                        view: "h5",
                        title: "Heading 5",
                        class: "ck-heading_heading5",
                      },
                      {
                        model: "heading6",
                        view: "h6",
                        title: "Heading 6",
                        class: "ck-heading_heading6",
                      },
                    ],
                  },
                }}
              />

              <label class="form-label" for="username">
                <b>Title (Ar)</b>
              </label>
              <input
                type="text"
                dir="rtl"
                id="username"
                class="form-control"
                value={artitle}
                onChange={(e) => setArTitle(e.target.value)}
              />

              <label class="form-label" for="username">
                <b>Description (Ar) </b>
              </label>
              <CKEditor
                config={{
                  language: {
                    ui: "ar", // UI language
                    content: "ar", // Content language
                  },
                  contentsLangDirection: "rtl", // Content direction
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "|",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "indent",
                    "outdent",
                    "|",
                    "imageUpload",
                    "blockQuote",
                    "insertTable",
                    "mediaEmbed",
                    "|",
                    "undo",
                    "redo",
                    "|",
                    "selectAll",
                  ],
                  heading: {
                    options: [
                      {
                        model: "paragraph",
                        title: "Paragraph",
                        class: "ck-heading_paragraph",
                      },
                      {
                        model: "heading1",
                        view: "h1",
                        title: "Heading 1",
                        class: "ck-heading_heading1",
                      },
                      {
                        model: "heading2",
                        view: "h2",
                        title: "Heading 2",
                        class: "ck-heading_heading2",
                      },
                      {
                        model: "heading3",
                        view: "h3",
                        title: "Heading 3",
                        class: "ck-heading_heading3",
                      },
                      {
                        model: "heading4",
                        view: "h4",
                        title: "Heading 4",
                        class: "ck-heading_heading4",
                      },
                      {
                        model: "heading5",
                        view: "h5",
                        title: "Heading 5",
                        class: "ck-heading_heading5",
                      },
                      {
                        model: "heading6",
                        view: "h6",
                        title: "Heading 6",
                        class: "ck-heading_heading6",
                      },
                    ],
                  },
                }}
                editor={ClassicEditor}
                data={artextEditor}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();

                  setArTextEditor(data);
                }}
              />
              <div className="card mb-3 mt-3">
                <h5 className="card-header">SEO (En)</h5>

                <div class="card-body">
                  <div className="mb-3">
                    <label className="form-label">Keywords</label>
                    <input
                      value={seoKeywordsEn}
                      onChange={(ev) => setSeoKeywordsEn(ev.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Description</label>
                    <input
                      value={seoDescEn}
                      onChange={(ev) => setSeoDescEn(ev.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Title</label>
                    <input
                      value={seoTitleEn}
                      onChange={(ev) => setSeoTitleEn(ev.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <div className="card mb-3">
                <h5 className="card-header">SEO (Ar)</h5>

                <div class="card-body">
                  <div className="mb-3">
                    <label className="form-label">Keywords</label>
                    <input
                      value={seoKeywordsAr}
                      onChange={(ev) => setSeoKeywordsAr(ev.target.value)}
                      className="form-control"
                      dir="rtl"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Description</label>
                    <input
                      value={seoDescAr}
                      onChange={(ev) => setSeoDescAr(ev.target.value)}
                      className="form-control"
                      dir="rtl"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Title</label>
                    <input
                      value={seoTitleAr}
                      onChange={(ev) => setSeoTitleAr(ev.target.value)}
                      className="form-control"
                      dir="rtl"
                    />
                  </div>
                </div>
              </div>
              <button
                class="btn btn-success btn-submit mt-4"
                onClick={handleUpdate}
              >
                Update
              </button>
              <ToastContainer />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
