import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const styles = {
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    },
    thumb: {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box'
    },
    thumbInner: {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    },
    img: {
        display: 'block',
        width: 'auto',
        height: '100%'
    }
};

const ImageDropzone = ({ onFilesAdded }) => {
    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            const mappedFiles = acceptedFiles.map(file =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            );
            setFiles(mappedFiles);
            if (onFilesAdded) {
                onFilesAdded(mappedFiles);
            }
        }
    });

    const thumbs = files.map(file => (
        <div style={styles.thumb} key={file.name}>
            <div style={styles.thumbInner}>
                <img
                    src={file.preview}
                    style={styles.img}
                    onLoad={() => {
                        URL.revokeObjectURL(file.preview);
                    }}
                />
            </div>
        </div>
    ));

    useEffect(() => {
        return () => {
            files.forEach(file => URL.revokeObjectURL(file.preview));
        };
    }, [files]);

    return (
        <>
            <div {...getRootProps({ className: 'dropzone' })} style={dropzoneStyles}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop a image file here, or click to select one</p>
            </div>
            <aside style={styles.thumbsContainer}>{thumbs}</aside>
        </>
    );
};

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
};
export default ImageDropzone;