import React from 'react'
import DermotologyForm from '../../Forms/SkinCare/DermotologyForm'
import DermotologyListComponent from '../../Components/Skincare/DermotologyListComponent'

export default function Dermotology() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <DermotologyForm />
                <DermotologyListComponent />
            </div>
        </div>
    )
}
