import React from 'react'
import HeaderForm from '../../Forms/Header/HeaderForm'
import HeaderListComponent from '../../Components/Header/HeaderListComponent'

export default function HeaderPage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
           <div className="row">
                <HeaderForm />
                <HeaderListComponent />

            </div>
        </div>
    )
}
