import React from 'react'
import SkinCareForm from '../../Forms/SkinCare/SkincareForm'

export default function SkinCarePage() {
  return (
    <div>
     <SkinCareForm />
    </div>
  )
}
