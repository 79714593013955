import React from 'react'
import VitaminCategoryForm from '../../Forms/Vitamin/VitaminCategoryForm'
import VitaminCategoryListComponent from '../../Components/Vitamin/VitaminCategoryListComponent'

export default function VitaminCategoryPage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col-lg-4">
                    <VitaminCategoryForm />
                </div>
                <div className='col-lg-8'>
                 <VitaminCategoryListComponent />
                </div>

            </div>
        </div>
    )
}
