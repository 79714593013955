import React, { useState } from 'react';
import { Field, ErrorMessage } from 'formik';

const PasswordField = ({ fieldName }) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <div className="mb-3 col-md-6 form-password-toggle fv-plugins-icon-container">
                <label className="form-label" htmlFor={fieldName}>
                    {fieldName === 'cpassword' ? 'Current Password' :
                        fieldName === 'npassword' ? 'New Password' : 'Confirm New Password'}
                </label>
                <div className="input-group input-group-merge has-validation">
                    <Field
                        name={fieldName}
                        type={showPassword ? 'text' : 'password'}
                        className="form-control"
                        placeholder={fieldName === 'cpassword' ? 'Enter Current Password' :
                            fieldName === 'npassword' ? 'Enter New Password' : 'Re-Enter Your New Password'}
                    />
                    <span className="input-group-text cursor-pointer" onClick={togglePasswordVisibility}>
                        {showPassword ? <i className="ti ti-eye"></i> : <i className="ti ti-eye-off"></i>}
                    </span>
                </div>
                <ErrorMessage
                    name={fieldName}
                    component="div"
                    className="text-danger"
                />
            </div>
        </>
    );
};

export default PasswordField;