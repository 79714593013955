import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { url } from '../Components/Common/Url'
import axios from 'axios';


const initialState = {
    user: null,
    token: null,
    loading: false,
    error: null
}

export const getUser = createAsyncThunk(
    'users/getUser',
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${url}/api/v1/user/login`, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const result = response.data;
            return result;
        } catch (err) {
            if (err.response) {
                const { status, data } = err.response;
                if (status === 400) {
                    return rejectWithValue(data.message || 'Email and Password are required');
                } else if (status === 401) {
                    return rejectWithValue(data.message || 'Invalid email or password');
                } else {
                    return rejectWithValue(data.message || 'An error occurred while logging in');
                }
            } else {
                return rejectWithValue('Oops, there seems to be an error');
            }
        }
    }
);


export const updateProfile = createAsyncThunk(
    'users/updateProfile',
    async (formData, { rejectWithValue, getState }) => {
        try {
            const state = getState();
            const token = state.user.token;
            const response = await axios.post(`${url}/api/v1/user/profile/update`, formData, {
                headers: {
                    "token": `${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            const result = response.data;
            return result;
        } catch (err) {
            if (err.response) {
                const { status, data } = err.response;
                return rejectWithValue(data.message || 'Failed to update profile');
            } else {
                return rejectWithValue('Oops, there seems to be an error');
            }
        }
    }
);


export const updatePassword = createAsyncThunk(
    'users/updatePassword',
    async (passwords, { rejectWithValue, getState }) => {
        try {
            const state = getState();
            const token = state.user.token;
            const response = await axios.put(`${url}/api/v1/user/password/update`, passwords, {
                headers: {
                    "token": `${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = response.data.message;
            return result;
        } catch (err) {
            if (err.response) {
                const { status, data } = err.response;
                return rejectWithValue(data.message || 'Failed to update password');
            } else {
                return rejectWithValue('Oops, there seems to be an error');
            }
        }
    }
);




export const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        logout: (state) => {
            state.user = null;
            state.token = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload.other;
                state.token = action.payload.token;
            })
            .addCase(getUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Login failed';
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload.other;
            });
    },
});


export const { logout } = userSlice.actions;

export const userReducer = userSlice.reducer;
