import React from 'react'
import PreventHealthDescriptionForm from '../../Forms/PatientCare/PreventHealthDescription'

export default function PreventHealthDescription() {
  return (
    <div>
        <PreventHealthDescriptionForm/>
    </div>
  )
}
