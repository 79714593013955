import React from 'react'
import HealthToolForm from '../../Forms/PatientCare/HealthToolForm'

export default function HealthToolPage() {
  return (
    <div>
        <HealthToolForm />
    </div>
  )
}
