import React from 'react'
import GerdRiskForm from '../../Forms/PatientCare/GerdRiskForm'

export default function GerdRiskPage() {
  return (
    <div>
        <GerdRiskForm />
    </div>
  )
}
