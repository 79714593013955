import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../Services/UserSlices';
import { ToastContainer, toast } from 'react-toastify';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

export default function LoginPage() {

    const dispatch = useDispatch();
    const navigation = useNavigate();
    const [loading, setLoading] = useState(false);
    const { token } = useSelector((state) => state.user);
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const initialValues = {
        email: "",
        password: "",
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string().required("Please enter user email").email("Invalid email format"),
        password: Yup.string().required("Please enter password"),
    });

    const handleLogin = async (formValue) => {
        const { email, password } = formValue;
        const data = { email, password };

        setLoading(true);
        toast.dismiss();
        try {
            const resultAction = await dispatch(getUser(data));

            if (getUser.fulfilled.match(resultAction)) {
                toast.success('Login Successful');
            } else {
                toast.error(resultAction.payload || 'Login failed');
            }
        } catch (error) {
            toast.error('Failed to fetch the user');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (token) {
            navigation('/admin/dashboard');
        }
    }, [token, navigation]);

    return (
        <>
            <ToastContainer position="top-right" />
            <div className="container-xxl">
                <div className="authentication-wrapper authentication-basic container-p-y">
                    <div className="authentication-inner py-4">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="mb-1 pt-2">Welcome to Aster Clinic! 👋</h4>
                                <p className="mb-4"></p>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleLogin}
                                >
                                    <Form className="mb-3">
                                        <div className="mb-3">
                                            <label className="form-label">Email</label>
                                            <Field name="email" type="text" className="form-control" placeholder="Enter your email" />
                                            <ErrorMessage name="email" component="span" className="errorInput" />
                                        </div>
                                        <div className="mb-3 form-password-toggle">
                                            <div className="d-flex justify-content-between">
                                                <label className="form-label">Password</label>
                                            </div>
                                            <div className="input-group input-group-merge">
                                                <Field
                                                    name="password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    className="form-control"
                                                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                                />
                                                <span className="input-group-text cursor-pointer" onClick={togglePasswordVisibility}>
                                                    {showPassword ? <i className="ti ti-eye"></i> : <i className="ti ti-eye-off"></i>}
                                                </span>
                                            </div>
                                            <ErrorMessage name="password" component="span" className="errorInput" />
                                        </div>
                                        <div className="mb-3">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="remember-me" />
                                                <label className="form-check-label"> Remember Me </label>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <button type="submit" className="btn btn-primary d-grid w-100" disabled={loading}>
                                                {loading && <span className="spinner-border spinner-border-sm"></span>}
                                                <span>Sign in</span>
                                            </button>
                                        </div>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
