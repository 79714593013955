import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify"; // Optional for notifications
import { useNavigate } from "react-router-dom"; // Use useNavigate instead of useHistory
import axios from "axios";
import { url } from "../../Common/Url";
import { useSelector } from "react-redux";
import i18n from "../../../i18n";

const BlogListComponent = ({ type }) => {
  const { token } = useSelector((state) => state.user);
  const [category, setCategory] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState([]);
  const [search, setSearch] = useState("");

  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  const get_blog = async () => {
    try {
      const { data } = await axios.get(`${url}/api/v1/blog/get/`, {
        headers: {
          token: `${token}`,
        },
      });
      console.log(data);
      setCategory(data.data);
      setFilteredCategory(data.data);
    } catch (error) {
      toast.error("Failed to fetch blogs.");
    }
  };
  const fetchEvents = async () => {
    try {
      const { data } = await axios.get(`${url}/api/v1/event/get/`, {
        headers: {
          token: `${token}`,
        },
      });
      console.log(data);
      setCategory(data);
      setFilteredCategory(data);
    } catch (error) {
      toast.error("Failed to fetch events.");
    }
  };

  useEffect(() => {
    if (type === "event") {
      fetchEvents();
    } else {
      get_blog();
    }
  }, []);

  useEffect(() => {
    const result = category.filter(
      (blog) =>
        blog.en_title.toLowerCase().includes(search.toLowerCase()) ||
        blog.ar_title.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredCategory(result);
  }, [search, category]);

  const handleStatus = async (id, s) => {
    try {
      let status = s === 1 ? 0 : 1;
      const formData = new FormData();
      formData.append("status", status);

      const { data } = await axios.post(
        `${url}/api/v1/blog/status/change/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            token: `${token}`,
          },
        }
      );
      console.log(data);
      toast.success(data.message);
      get_blog();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleEventStatus = async (id, s) => {
    console.log(id, s);
    try {
      let status = s === 1 ? 0 : 1;
      const formData = new FormData();
      formData.append("status", status);

      const { data } = await axios.post(
        `${url}/api/v1/event/status/change/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            token: `${token}`,
          },
        }
      );
      console.log(data);
      toast.success(data.message);
      fetchEvents();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handledelete = async (id) => {
    const confirm = window.confirm("Are you sure to delete this blog?");
    if (confirm) {
      try {
        const { data } = await axios.delete(`${url}/api/v1/blog/delete/${id}`, {
          headers: {
            "Content-Type": "application/json",
            token: `${token}`,
          },
        });
        console.log(data);
        toast.success(data.message);
        get_blog();
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const handleEventdelete = async (id) => {
    const confirm = window.confirm("Are you sure to delete this blog?");
    if (confirm) {
      try {
        const { data } = await axios.delete(
          `${url}/api/v1/event/delete/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              token: `${token}`,
            },
          }
        );

        console.log(data);
        toast.success(data.message);
        fetchEvents();
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const handleEdit = async (id) => {
    navigate(`/admin/blog/add/${id}`, { state: { type: "blog" } });
  };
  const handleEventEdit = async (id) => {
    navigate(`/admin/blog/add/${id}`, { state: { type: "event" } });
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Title (English)",
      selector: (row) => row.en_title,
      sortable: true,
    },
    {
      name: "Title (Arabic)",
      selector: (row) => row.ar_title,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          <label className="switch">
            <input
              type="checkbox"
              className="switch-input"
              checked={row.status === 1}
              onChange={() =>
                type === "event"
                  ? handleEventStatus(row._id, row.status)
                  : handleStatus(row._id, row.status)
              }
            />
            <span className="switch-toggle-slider">
              {row.status === 1 ? (
                <span className="switch-on">
                  <i className="ti ti-check"></i>
                </span>
              ) : (
                <span className="switch-off">
                  <i className="ti ti-x"></i>
                </span>
              )}
            </span>
            <span className="switch-label">
              {row.status === 1 ? "Published" : "Draft"}
            </span>
          </label>
        </>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <button
            className="btn btn-primary btn-sm me-3"
            onClick={() =>
              type === "event" ? handleEventEdit(row._id) : handleEdit(row._id)
            }
          >
            Edit
          </button>
          <button
            className="btn btn-danger btn-sm"
            onClick={() =>
              type === "event"
                ? handleEventdelete(row._id)
                : handledelete(row._id)
            }
          >
            Delete
          </button>
        </>
      ),
    },
  ];

  return (
    <div className="card mb-3">
      <h5 className="card-header">
        {type && type === "event" ? "Event List" : "Category List"}
      </h5>
      <div className="card-body">
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search by title..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <DataTable
          columns={columns}
          data={filteredCategory}
          pagination
          highlightOnHover
          striped
        />
      </div>
    </div>
  );
};

export default BlogListComponent;
