import React, { useEffect, useState } from 'react';
import FormServices from '../Services/FormServices';
import FacilityList from './FacilityList';
import FacilityForm from '../Forms/FacilityForm';
import { toast } from 'react-toastify';

const ManageFacilitiesPage = () => {
    const [facilities, setFacilities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchFacilities();
    }, []);

    const handleCreate = async() =>{
        fetchFacilities();
    };

    const fetchFacilities = async () => {
        setLoading(true);
        try {
            const data = await FormServices.fetchFacilities();
            setFacilities(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateFacility = async (facilityId, formData) => {
        toast.dismiss();
        console.log('update ==>', formData);
        try {
            await FormServices.updateFacility(facilityId, formData); // Replace with actual API call
            toast.success('Facility updated successfully');
            fetchFacilities();
        } catch (err) {
            toast.error('Error updating Facility');
        }
    };

    const handleDeleteFacility = async (facilityId) => {
        toast.dismiss();
        const confirmed = window.confirm('Are you sure you want to delete this Facility?');
        if (confirmed) {
            try {
                await FormServices.deleteFacility(facilityId); // Replace with actual API call
                toast.success('Facility deleted successfully');
                fetchFacilities();
            } catch (err) {
                toast.error('Error deleting Facility');
            }
        }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <>

            <div className="container-xxl flex-grow-1 container-p-y">
                <FacilityForm onCreate={handleCreate}/>
                <div className="row">
                    <FacilityList facilities={facilities} onDelete={handleDeleteFacility} onUpdateFacility={handleUpdateFacility} />
                </div>
            </div>
        </>

    );
};

export default ManageFacilitiesPage;
