import React from 'react'
import SliderForm from '../../Forms/Wellness/SliderForm'
import SliderListComponent from '../../Components/Wellness/SliderListComponent'
import DescriptionForm from '../../Forms/Wellness/DescriptionForm'

export default function SliderPage() {
    return (
        <div className="container-xxl container-p-y">

            <div className="row">
                <SliderForm />
                <SliderListComponent />
                <DescriptionForm />
            </div>
        </div>
    )
}
