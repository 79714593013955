import React, { useEffect, useState } from 'react';
import FormServices from '../Services/FormServices'; // Adjust the import path as needed
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify'; // Optional for notifications
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory


const SpecialtyList = () => {
    const [specialties, setSpecialties] = useState([]);
    const [filterData, setFilterData] = useState('');
    const [filter, setFilter] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Use useNavigate instead of useHistory

    const fetchSpecialties = async () => {
        setLoading(true);
        try {
            const data = await FormServices.fetchSpecialties();
            setSpecialties(data);
            setFilter(data)
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSpecialties();
    }, []);



    useEffect(() => {
        const filterredspecilities = specialties.filter((specialiy) => {

            const titleEnglish = specialiy?.titleEn?.toLowerCase() || '';
            const titleArebic = specialiy?.titleAr?.toLowerCase() || '';


            return titleEnglish.includes(filterData.toLowerCase()) || titleArebic.includes(filterData.toLowerCase());
        })
        setFilter(filterredspecilities)
    }, [filterData, specialties])

    const handleEdit = (id) => {
        navigate(`/admin/edit-specialty/${id}`); // Use navigate instead of history.push
    };

    const handleManageFAQ = (id) => {
        navigate(`/admin/manage-specialty-faq/${id}`); // Navigate to manage FAQ page for the selected specialty
    };

    const handleDelete = async (id) => {
        const confirmed = window.confirm('Are you sure you want to delete this specialty?');
        if (confirmed) {
            toast.dismiss();
            try {
                await FormServices.deleteSpecialty(id);
                fetchSpecialties();
                toast.success('Specialty deleted successfully');
            } catch (err) {
                toast.error('Error deleting specialty');
            }
        }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Title (English)',
            selector: row => row.titleEn,
            sortable: true,
        },
        {
            name: 'Title (Arabic)',
            selector: row => row.titleAr,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => (
                <>
                    <button className="btn btn-info btn-sm me-3" onClick={() => handleManageFAQ(row._id)}>Manage FAQ</button>
                    <button className="btn btn-primary btn-sm me-3" onClick={() => handleEdit(row._id)}>Edit</button>
                    <button className="btn btn-danger btn-sm" onClick={() => handleDelete(row._id)}>Delete</button>
                </>
            ),
        },
    ];

    console.log(filter)

    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <div className="col">
                    <div className="card mb-3">
                        <h5 className="card-header">Specialty List</h5>

                        <div className="card-body">
                            <input type='text' value={filterData} onChange={(e) => setFilterData(e.target.value)} className='form-control' placeholder='search by title'/>
                            <DataTable
                                columns={columns}
                                data={filter}
                                pagination
                                highlightOnHover
                                striped
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpecialtyList;
