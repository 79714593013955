import React from 'react'
import VitaminFaqForm from '../../Forms/Vitamin/VitaminFaqForm'
import VitaminFaqListComponent from '../../Components/Vitamin/VitaminFaqListComponent'

export default function VitaminFaqPage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <VitaminFaqForm />
                <VitaminFaqListComponent />
            </div>
        </div>
    )
}
