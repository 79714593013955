import React from 'react'
import VitaminTherapyForm from '../../Forms/Vitamin/VitaminTherapyForm'
import VitaminTherapyListComponent from '../../Components/Vitamin/VitaminTherapyListComponent'

export default function VitaminTherapyPage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <VitaminTherapyForm />
                <VitaminTherapyListComponent />
            </div>
        </div>
    )
}
