import React, { useEffect, useState } from "react";
import FormServices from "../../Services/FormServices"; // Adjust the import path as needed
import DataTable from "react-data-table-component";
import { toast } from "react-toastify"; // Optional for notifications
import { useNavigate } from "react-router-dom"; // Use useNavigate instead of useHistory

const ClinicsList = () => {
  const [clinics, setClinics] = useState([]);
  const [filter, setFilter] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  const fetchClinics = async () => {
    setLoading(true);
    try {
      const data = await FormServices.fetchClinics();
      setClinics(data);
      setFilter(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClinics();
  }, []);

  useEffect(() => {
    const filterclinic = clinics.filter((clinic) => {
      const titleEnglish = clinic?.title?.toLowerCase() || "";
      const titleArabic = clinic?.ar?.title?.toLowerCase() || "";
      return (
        titleEnglish.includes(searchText?.toLowerCase()) ||
        titleArabic.includes(searchText?.toLowerCase())
      );
    });

    setFilter(filterclinic);
  }, [searchText, clinics]);

  const handleEdit = (id) => {
    navigate(`/admin/edit-clinic/${id}`); // Use navigate instead of history.push
  };

  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this clinic?"
    );
    if (confirmed) {
      toast.dismiss();
      try {
        await FormServices.deleteClinic(id);
        fetchClinics();
        toast.success("Clinic deleted successfully");
      } catch (err) {
        toast.error("Error deleting clinic");
      }
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const getStatusBadge = (status) => {
    if (status === "published") {
      return <span className="badge bg-label-primary me-1">Published</span>;
    } else if (status === "pending") {
      return <span className="badge bg-label-warning me-1">Pending</span>;
    } else if (status === "draft") {
      return <span className="badge bg-label-info me-1">Draft</span>;
    } else {
      return null;
    }
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row?.title,
      sortable: true,
    },
    {
      name: "Title (ar)",
      selector: (row) => row?.ar?.title,
      sortable: true,
    },
    {
      name: "Locations",
      selector: (row) =>
        `${row?.areaId?.name}, ${row?.cityId?.name}, ${row?.locationId?.name}`,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => getStatusBadge(row.status),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <button
            className="btn btn-primary btn-sm me-3"
            onClick={() => handleEdit(row._id)}
          >
            Edit
          </button>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => handleDelete(row._id)}
          >
            Delete
          </button>
        </>
      ),
    },
  ];

  console.log(clinics);

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="col">
          <div className="card mb-3">
            <h5 className="card-header">Clinics List</h5>
            <div className="card-body">
              <input
                type="text"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="form-control"
                placeholder="search by title"
              />
              <DataTable
                columns={columns}
                data={filter}
                pagination
                highlightOnHover
                striped
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicsList;
