import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import "../i18n"; // Assuming i18n setup for language switching
import { url } from "../Components/Common/Url";

const EditPageForm = () => {
  const { t, i18n } = useTranslation();
  const { token } = useSelector((state) => state.user);
  const { id } = useParams();
  const navigate = useNavigate();

  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [formData, setFormData] = useState({
    slug: "",
    titleEn: "",
    titleAr: "",
    descriptionEn: "",
    descriptionAr: "",
    metaTitleEn: "",
    metaDescriptionEn: "",
    metaKeywordsEn: "",
    metaTitleAr: "",
    metaDescriptionAr: "",
    metaKeywordsAr: "",
  });

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const response = await axios.get(`${url}/api/v1/setting/pages/${id}`);
        
        // Check if response data exists and is structured correctly
        if (response && response.data) {
          // Update formData to include descriptions and other fields
          setFormData({
            slug: response.data.slug || "",
            titleEn: response.data.titleEn || "",
            titleAr: response.data.titleAr || "",
            descriptionEn: response.data.descriptionEn || "",
            descriptionAr: response.data.descriptionAr || "",
            metaTitleEn: response.data.metaTitleEn || "",
            metaDescriptionEn: response.data.metaDescriptionEn || "",
            metaKeywordsEn: response.data.metaKeywordsEn || "",
            metaTitleAr: response.data.metaTitleAr || "",
            metaDescriptionAr: response.data.metaDescriptionAr || "",
            metaKeywordsAr: response.data.metaKeywordsAr || "",
          });
        }
      } catch (error) {
        console.error("Error fetching page data", error);
        toast.error("Failed to fetch page data");
      }
    };
  
    fetchPageData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEditorChange = (editor, language) => {
    const data = editor.getData();
    if (language === "en") {
      setFormData((prevState) => ({
        ...prevState,
        descriptionEn: data,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        descriptionAr: data,
      }));
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${url}/api/v1/setting/pages/${id}`, formData, {
        headers: {
          token: `${token}`,
        },
      });
      toast.success("Page updated successfully!");
      navigate("/admin/page-list"); // Redirect after successful update
    } catch (error) {
      console.error("Error updating page:", error);
      toast.error("There was an error updating the page.");
    }
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <ToastContainer />
      <div className="row card pt-3">
        <h3>Edit Page</h3>
        <div className="col">
          <div className="card mb-3">
            <div className="card-header pt-2">
              <ul className="nav nav-tabs card-header-tabs" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeLanguage === "en" ? "active" : ""}`}
                    onClick={() => changeLanguage("en")}
                  >
                    English
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeLanguage === "ar" ? "active" : ""}`}
                    onClick={() => changeLanguage("ar")}
                  >
                    Arabic
                  </button>
                </li>
              </ul>
            </div>

            <div className="tab-content">
              <div className="tab-pane fade active show" role="tabpanel">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="slug" className="form-label">
                      Slug
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="slug"
                      name="slug"
                      value={formData.slug}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  {activeLanguage === "en" ? (
                    <>
                      <div className="mb-3">
                        <label htmlFor="titleEn" className="form-label">
                          Title (English)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="titleEn"
                          name="titleEn"
                          value={formData.titleEn}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="descriptionEn" className="form-label">
                          Description (English)
                        </label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={formData.descriptionEn}
                          onChange={(event, editor) =>
                            handleEditorChange(editor, "en")
                          }
                          config={{
                            language: "en",
                            contentsLangDirection: "ltr",
                            toolbar: [
                                "heading",
                                "|",
                                "bold",
                                "italic",
                                "|",
                                "link",
                                "bulletedList",
                                "numberedList",
                                "|",
                                "indent",
                                "outdent",
                                "|",
                                "imageUpload",
                                "blockQuote",
                                "insertTable",
                                "mediaEmbed",
                                "|",
                                "undo",
                                "redo",
                                "|",
                                "selectAll",
                              ],
                              heading: {
                                options: [
                                  {
                                    model: "paragraph",
                                    title: "Paragraph",
                                    class: "ck-heading_paragraph",
                                  },
                                  {
                                    model: "heading1",
                                    view: "h1",
                                    title: "Heading 1",
                                    class: "ck-heading_heading1",
                                  },
                                  {
                                    model: "heading2",
                                    view: "h2",
                                    title: "Heading 2",
                                    class: "ck-heading_heading2",
                                  },
                                  {
                                    model: "heading3",
                                    view: "h3",
                                    title: "Heading 3",
                                    class: "ck-heading_heading3",
                                  },
                                  {
                                    model: "heading4",
                                    view: "h4",
                                    title: "Heading 4",
                                    class: "ck-heading_heading4",
                                  },
                                  {
                                    model: "heading5",
                                    view: "h5",
                                    title: "Heading 5",
                                    class: "ck-heading_heading5",
                                  },
                                  {
                                    model: "heading6",
                                    view: "h6",
                                    title: "Heading 6",
                                    class: "ck-heading_heading6",
                                  },
                                ],
                              },
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="metaTitleEn" className="form-label">
                          Meta Title (English)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="metaTitleEn"
                          name="metaTitleEn"
                          value={formData.metaTitleEn}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="metaDescriptionEn" className="form-label">
                          Meta Description (English)
                        </label>
                        <textarea
                          className="form-control"
                          id="metaDescriptionEn"
                          name="metaDescriptionEn"
                          rows="2"
                          value={formData.metaDescriptionEn}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="metaKeywordsEn" className="form-label">
                          Meta Keywords (English)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="metaKeywordsEn"
                          name="metaKeywordsEn"
                          value={formData.metaKeywordsEn}
                          onChange={handleChange}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="mb-3" dir="rtl">
                        <label htmlFor="titleAr" className="form-label">
                          Title (Arabic)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="titleAr"
                          name="titleAr"
                          dir="rtl"
                          value={formData.titleAr}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="mb-3" dir="rtl">
                        <label htmlFor="descriptionAr" className="form-label">
                          Description (Arabic)
                        </label>
                        <CKEditor
                         key={`editor1-${activeLanguage}`}
                          editor={ClassicEditor}
                          data={formData.descriptionAr}
                          onChange={(event, editor) =>
                            handleEditorChange(editor, "ar")
                          }
                          config={{
                            language: "ar",
                            contentsLangDirection: "rtl",
                            toolbar: [
                                "heading",
                                "|",
                                "bold",
                                "italic",
                                "|",
                                "link",
                                "bulletedList",
                                "numberedList",
                                "|",
                                "indent",
                                "outdent",
                                "|",
                                "imageUpload",
                                "blockQuote",
                                "insertTable",
                                "mediaEmbed",
                                "|",
                                "undo",
                                "redo",
                                "|",
                                "selectAll",
                              ],
                              heading: {
                                options: [
                                  {
                                    model: "paragraph",
                                    title: "Paragraph",
                                    class: "ck-heading_paragraph",
                                  },
                                  {
                                    model: "heading1",
                                    view: "h1",
                                    title: "Heading 1",
                                    class: "ck-heading_heading1",
                                  },
                                  {
                                    model: "heading2",
                                    view: "h2",
                                    title: "Heading 2",
                                    class: "ck-heading_heading2",
                                  },
                                  {
                                    model: "heading3",
                                    view: "h3",
                                    title: "Heading 3",
                                    class: "ck-heading_heading3",
                                  },
                                  {
                                    model: "heading4",
                                    view: "h4",
                                    title: "Heading 4",
                                    class: "ck-heading_heading4",
                                  },
                                  {
                                    model: "heading5",
                                    view: "h5",
                                    title: "Heading 5",
                                    class: "ck-heading_heading5",
                                  },
                                  {
                                    model: "heading6",
                                    view: "h6",
                                    title: "Heading 6",
                                    class: "ck-heading_heading6",
                                  },
                                ],
                              },
                          }}
                        />
                      </div>
                      <div className="mb-3" dir="rtl">
                        <label htmlFor="metaTitleAr" className="form-label">
                          Meta Title (Arabic)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="metaTitleAr"
                          name="metaTitleAr"
                          dir="rtl"
                          value={formData.metaTitleAr}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3" dir="rtl">
                        <label htmlFor="metaDescriptionAr" className="form-label">
                          Meta Description (Arabic)
                        </label>
                        <textarea
                          className="form-control"
                          id="metaDescriptionAr"
                          name="metaDescriptionAr"
                          rows="2"
                          value={formData.metaDescriptionAr}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <div className="mb-3" dir="rtl">
                        <label htmlFor="metaKeywordsAr" className="form-label">
                          Meta Keywords (Arabic)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="metaKeywordsAr"
                          name="metaKeywordsAr"
                          dir="rtl"
                          value={formData.metaKeywordsAr}
                          onChange={handleChange}
                        />
                      </div>
                    </>
                  )}

                  <div className="form-group d-flex justify-content-between">
                    <button type="submit" className="btn btn-primary">
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPageForm;
