import React from 'react'
import VitaminTherapyTypeForm from '../../Forms/Vitamin/VitaminTherapyTypeForm'
import VitaminTherapyTypeListComponent from '../../Components/Vitamin/VitaminTherapyTypeListComponent'


export default function VitaminTherapyTypePage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <VitaminTherapyTypeForm />
                <VitaminTherapyTypeListComponent />
            </div>
        </div>
    )
}
