import React from "react";
import AddOffers from "./AddNews";
import { useLocation } from "react-router-dom";
import AddNews from "./AddNews";

export default function News() {
  const location = useLocation();
  const data = location.state.type || {};
  console.log(data);
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <AddNews type={data} />
      </div>
    </div>
  );
}
