import React, { useEffect, useState } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { url } from "../Common/Url";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

export default function Vission() {
  const [textEditor, setTextEditor] = useState("");
  const [textmEditor, setTextMEditor] = useState("");
  const [hidden, setHidden] = useState(true);
  const [lan, setLan] = useState("en");
  const [data, setData] = useState({});
  const { token } = useSelector((state) => state.user);

  const [seoTitleEn, setSeoTitleEn] = useState("");
  const [seoKeywordsEn, setSeoKeywordsEn] = useState("");
  const [seoDescEn, setSeoDescEn] = useState("");
  const [seoTitleAr, setSeoTitleAr] = useState("");
  const [seoKeywordsAr, setSeoKeywordsAr] = useState("");
  const [seoDescAr, setSeoDescAr] = useState("");

  const get_aster_clinic = async () => {
    const response = await fetch(`${url}/api/v1/user/vission`, {
      method: "GET",
      headers: {
        token: `${token}`,
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();
    console.log(result);
    setData(result.data);
  };

  useEffect(() => {
    get_aster_clinic();
  }, []);

  const handleLanguge = (e) => {
    if (e.target.value == "Ar") {
      setLan("Ar");
      setTextEditor(data.ar_vision);
      setTextMEditor(data.ar_mission);
      setHidden(false);
      setSeoKeywordsAr(data.seoKeywordsAr);
      setSeoDescAr(data.seoDescAr);
      setSeoTitleAr(data.seoTitleAr);
    } else {
      setLan("en");
      setTextMEditor(data.en_mission);
      setTextEditor(data.en_vision);
      setHidden(false);
      setSeoKeywordsEn(data.seoKeywordsEn);
      setSeoDescEn(data.seoDescEn);
      setSeoTitleEn(data.seoTitleEn);
    }
  };

  const handleVission = async () => {
    console.log(textEditor);
    const response = await fetch(`${url}/api/v1/user/vission/update`, {
      method: "PUT",
      headers: {
        token: `${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        lan,
        textEditor,
        textmEditor,
        seoTitleEn,
        seoKeywordsEn,
        seoDescEn,
        seoTitleAr,
        seoKeywordsAr,
        seoDescAr,
      }),
    });
    const result = await response.json();
    toast(result.message);
    if (result.status == true) {
      setInterval(() => {
        window.location.reload();
      }, 3000);
    }
  };

  return (
    <div class="row g-3">
      <div class="col-md-12">
        <h4>Vission Mission</h4>
        <label for="select2Basic" class="form-label">
          <b>Language</b>
        </label>
        <select
          id="select2Basic"
          class="select2 form-select"
          data-allow-clear="true"
          onChange={(e) => handleLanguge(e)}
        >
          <option value="">Select</option>
          <option value="En">En</option>
          <option value="Ar">Ar</option>
        </select>
      </div>
      {hidden == false && (
        <>
          <div class="col-sm-12">
            <label class="form-label" for="username">
              <b>Vision</b>
            </label>
            <CKEditor
              key={`editor1-${lan}`}
              config={{
                language: {
                  ui: lan === "Ar" ? "ar" : "en", // UI language
                  content: lan === "Ar" ? "ar" : "en", // Content language
                },
                contentsLangDirection: lan === "Ar" ? "rtl" : "ltr", // Content direction
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "|",
                  "link",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "indent",
                  "outdent",
                  "|",
                  "imageUpload",
                  "blockQuote",
                  "insertTable",
                  "mediaEmbed",
                  "|",
                  "undo",
                  "redo",
                  "|",
                  "selectAll",
                ],
                heading: {
                  options: [
                    {
                      model: "paragraph",
                      title: "Paragraph",
                      class: "ck-heading_paragraph",
                    },
                    {
                      model: "heading1",
                      view: "h1",
                      title: "Heading 1",
                      class: "ck-heading_heading1",
                    },
                    {
                      model: "heading2",
                      view: "h2",
                      title: "Heading 2",
                      class: "ck-heading_heading2",
                    },
                    {
                      model: "heading3",
                      view: "h3",
                      title: "Heading 3",
                      class: "ck-heading_heading3",
                    },
                    {
                      model: "heading4",
                      view: "h4",
                      title: "Heading 4",
                      class: "ck-heading_heading4",
                    },
                    {
                      model: "heading5",
                      view: "h5",
                      title: "Heading 5",
                      class: "ck-heading_heading5",
                    },
                    {
                      model: "heading6",
                      view: "h6",
                      title: "Heading 6",
                      class: "ck-heading_heading6",
                    },
                  ],
                },
              }}
              editor={ClassicEditor}
              data={textEditor}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
              }}
              onChange={(event, editor) => {
                const data = editor.getData();

                setTextEditor(data);
              }}
            />
            <label class="form-label" for="username">
              <b>Mission</b>
            </label>
            <CKEditor
              key={`editor2-${lan}`}
              config={{
                language: {
                  ui: lan === "Ar" ? "ar" : "en", // UI language
                  content: lan === "Ar" ? "ar" : "en", // Content language
                },
                contentsLangDirection: lan === "Ar" ? "rtl" : "ltr", // Content direction
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "|",
                  "link",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "indent",
                  "outdent",
                  "|",
                  "imageUpload",
                  "blockQuote",
                  "insertTable",
                  "mediaEmbed",
                  "|",
                  "undo",
                  "redo",
                  "|",
                  "selectAll",
                ],
                heading: {
                  options: [
                    {
                      model: "paragraph",
                      title: "Paragraph",
                      class: "ck-heading_paragraph",
                    },
                    {
                      model: "heading1",
                      view: "h1",
                      title: "Heading 1",
                      class: "ck-heading_heading1",
                    },
                    {
                      model: "heading2",
                      view: "h2",
                      title: "Heading 2",
                      class: "ck-heading_heading2",
                    },
                    {
                      model: "heading3",
                      view: "h3",
                      title: "Heading 3",
                      class: "ck-heading_heading3",
                    },
                    {
                      model: "heading4",
                      view: "h4",
                      title: "Heading 4",
                      class: "ck-heading_heading4",
                    },
                    {
                      model: "heading5",
                      view: "h5",
                      title: "Heading 5",
                      class: "ck-heading_heading5",
                    },
                    {
                      model: "heading6",
                      view: "h6",
                      title: "Heading 6",
                      class: "ck-heading_heading6",
                    },
                  ],
                },
              }}
              editor={ClassicEditor}
              data={textmEditor}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
              }}
              onChange={(event, editor) => {
                const data = editor.getData();

                setTextMEditor(data);
              }}
            />
            {lan === "en" ? (
              <>
                <div className="card mb-3 mt-3">
                  <h5 className="card-header">SEO (En)</h5>

                  <div class="card-body">
                    <div className="mb-3">
                      <label className="form-label">Keywords</label>
                      <input
                        value={seoKeywordsEn}
                        onChange={(ev) => setSeoKeywordsEn(ev.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Description</label>
                      <input
                        value={seoDescEn}
                        onChange={(ev) => setSeoDescEn(ev.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Title</label>
                      <input
                        value={seoTitleEn}
                        onChange={(ev) => setSeoTitleEn(ev.target.value)}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="card mb-3">
                  <h5 className="card-header">SEO (Ar)</h5>

                  <div class="card-body">
                    <div className="mb-3">
                      <label className="form-label">Keywords</label>
                      <input
                        value={seoKeywordsAr}
                        onChange={(ev) => setSeoKeywordsAr(ev.target.value)}
                        className="form-control"
                        dir="rtl"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Description</label>
                      <input
                        value={seoDescAr}
                        onChange={(ev) => setSeoDescAr(ev.target.value)}
                        className="form-control"
                        dir="rtl"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Title</label>
                      <input
                        value={seoTitleAr}
                        onChange={(ev) => setSeoTitleAr(ev.target.value)}
                        className="form-control"
                        dir="rtl"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <button
              class="btn btn-success btn-submit mt-4"
              onClick={handleVission}
            >
              Submit
            </button>
            <ToastContainer />
          </div>
        </>
      )}
    </div>
  );
}
