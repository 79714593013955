import React from 'react'
import EmotionalSliderForm from '../../Forms/PatientCare/EmotionalSliderForm'
import EmotionalSliderListComponent from '../../Components/PatientCare/EmotionalSliderListComponennt'


export default function EmotionalSliderPage() {
    return (
        <div>
            <EmotionalSliderForm />
            <EmotionalSliderListComponent />
        </div>
    )
}
