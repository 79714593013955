import React, { useEffect, useState } from 'react'
import { Image, url } from '../Common/Url';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function ClientListComponent() {
    const { token } = useSelector((state) => state.user)
    const navigate = useNavigate()

    const [services, setServices] = useState([])

    const get_trust_data = async () => {
        const { data } = await axios.get(`${url}/api/v1/client/get/`, {
            headers: {
                'token': `${token}`
            },
        });
        console.log(data)
        setServices(data.data)
    }
    useEffect(() => {
        get_trust_data()
    }, [])


    const handleStatus = async (id, s) => {
        try {
            let status = 0;
            if (s == 1) {
                status = 0
            } else {
                status = 1;
            }
            const formData = new FormData();
            formData.append('status', status);

            const { data } = await axios.post(`${url}/api/v1/client/status/change/${id}`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    "token": `${token}`,

                }
            })

            console.log(data)
            toast.success(data.message)
            get_trust_data()

        } catch (error) {
            toast.error(error)
        }
    }


    const handledelete = async (id) => {
        const confirm = window.confirm('Are you sure to delete this?')
        if (confirm) {
            try {
                const { data } = await axios.delete(`${url}/api/v1/client/delete/${id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        "token": `${token}`,

                    }
                })
                console.log(data)
                toast.success(data.message)
                get_trust_data()

            } catch (error) {
                toast.error(error)
            }
        }
    }

    const handleEdit = async (id) => {
        navigate(`/admin/wellness/client/${id}`)
    }

    return (
        <div className='col-md-8'>
            <ToastContainer />
            <div className='card'>
                <div className='card-body'>
                    <div className='services'>
                        <h4>Corporate Wellness Client</h4>

                        <ul class="timeline timeline-outline mb-0">
                            {
                                services.length > 0 ? <>
                                    {
                                        services.map((item, i) => {
                                            return (
                                                <li className="timeline-item timeline-item-transparent" key={i}>
                                                    <span className="timeline-point timeline-point-primary"></span>
                                                    <div className="timeline-event">
                                                        <div className="timeline-header mb-3">
                                                            <h6 className="mb-0">{item.en_title}
                                                                <br />
                                                                {item.ar_title}
                                                            </h6>
                                                            <small className="text-muted">
                                                                <label className="switch">
                                                                    <input type="checkbox" class="switch-input" checked={item.status == 1 ? 'true' : ''} onClick={() => handleStatus(item._id, item.status)} />
                                                                    <span className="switch-toggle-slider">
                                                                        {
                                                                            item.status == 1 ? <span class="switch-on">
                                                                                <i className="ti ti-check"></i>
                                                                            </span> : <span class="switch-off">
                                                                                <i className="ti ti-x"></i>
                                                                            </span>
                                                                        }


                                                                    </span>
                                                                    <span className="switch-label">{item.status == 1 ? 'Published' : 'Draft'}</span>
                                                                </label>
                                                                <button type="button" className="btn btn-xs btn-success waves-effect waves-light me-2" onClick={() => handleEdit(item._id)}>Edit</button>
                                                                <button type="button" className="btn btn-xs btn-danger waves-effect waves-light" onClick={() => handledelete(item._id)}>Delete</button>
                                                            </small>
                                                        </div>
                                                        <div className="d-flex align-items-center mb-1">
                                                            <div className="badge bg-lighter rounded mb-1_5">
                                                                <img src={`${Image}/${item.en_image}`} alt="img" width="50" className="me-2" />

                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </> : <>
                                    <p>No data found...</p>
                                </>
                            }




                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
