import React from 'react'
import DescriptionForm from '../../Forms/RtPcr/DescriptionFrom'

export default function DescriptionPage() {
  return (
    <div>
      <DescriptionForm />
    </div>
  )
}
