import React, { useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import Select from 'react-select';


const statusOptions = [
    { value: 'publish', label: 'Publish' },
    { value: 'unpublished', label: 'Unpublish' }
];


const validationSchema = Yup.object().shape({
    question: Yup.string().required('Question is required'),
    answer: Yup.string().required('Answer is required'),
    status: Yup.string().required('Status is required'),
});

const SpecialityFAQForm = ({ onSubmit, faq, language }) => {

    const initialValues = {
        question: faq ? faq.question : '',
        answer: faq ? faq.answer : '',
        language: faq ? faq.language : language,
        status: faq ? faq.status || '' : '',
    };


    const SetFieldValues = ({ setFieldValue }) => {
        useEffect(() => {
            if (faq) {
                setFieldValue('question', faq.question);
                setFieldValue('answer', faq.answer);
                setFieldValue('language', faq.language);
            }
        }, [faq, setFieldValue]);

        return null;
    };

    return (
        <div className="row">
            <div className="col-xl-12 mb-12 mb-xl-0">
                <div className={faq ? '' : 'card'}>
                    <h5 className={faq ? '' : 'card-header'}>{faq ? '' : 'Add FAQ'}</h5>
                    <div className="card-body">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            enableReinitialize={true}
                            onSubmit={(values, { resetForm }) => {
                                onSubmit(values);
                                resetForm();
                            }}
                        >
                            {({ isSubmitting, setFieldValue }) => (
                                <Form>
                                    <SetFieldValues setFieldValue={setFieldValue} />
                                    <div className="mb-3">
                                        <label htmlFor="question">Question</label>
                                        <Field  dir={ language =='arabic' ? 'rtl' : 'ltr'} type="text" name="question" className="form-control" />
                                        <ErrorMessage name="question" component="div" className="text-danger" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="answer">Answer</label>
                                        <Field dir={ language =='arabic' ? 'rtl' : 'ltr'} as="textarea" name="answer" className="form-control" rows={3} />
                                        <ErrorMessage name="answer" component="div" className="text-danger" />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Status</label>
                                        <Select
                                            options={statusOptions}
                                            onChange={(option) => setFieldValue('status', option.value)}
                                            value={statusOptions.find(option => option.value === initialValues.status)}
                                        />
                                        <ErrorMessage name="status" component="div" className="text-danger" />
                                    </div>
                                    <Button type="submit" variant="primary" disabled={isSubmitting}>
                                        {faq ? 'Update' : 'Submit'}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpecialityFAQForm;
