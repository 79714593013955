import React from 'react'
import StepForm from '../../Forms/Teleconsultant/StepForm'
import StepListComponent from '../../Components/Teleconsultant/StepListComponent'

export default function Step() {
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <StepForm />
        <StepListComponent />
      </div>
    </div>
  )
}
