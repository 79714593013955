import React, { useState } from 'react';
import { Modal, Button, Badge } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import EditFacilityForm from '../Forms/EditFacilityForm'; // Import the form component

const FacilityList = ({ facilities, onDelete, onUpdateFacility }) => {
    const [show, setShow] = useState(false);
    const [currentFacility, setCurrentFacility] = useState(null);

    const handleEdit = (facility) => {
        setCurrentFacility(facility);
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setCurrentFacility(null);
    };

    const handleSubmit = (values) => {
        if (currentFacility) {
            onUpdateFacility(currentFacility._id, values);
            handleClose();
        }
    };

    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Title (English)',
            selector: row => row.titleEn,
            sortable: true,
        },
        {
            name: 'Title (Arabic)',
            selector: row => row.titleAr,
            sortable: true,
        },
        {
            name: 'Status',
            cell: row => (
                <>
                    {row.status === 'publish' ? (
                        <Button size="sm" variant="success" className="me-2">Published</Button>
                    ) : (
                        <Button size="sm" variant="danger" className="me-2">Unpublished</Button>
                    )}
                </>
            ),
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => (
                <>
                    <Button
                        variant="info"
                        size="sm"
                        className="me-2"
                        onClick={() => handleEdit(row)}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="danger"
                        size="sm"
                        onClick={() => onDelete(row._id)}
                    >
                        Delete
                    </Button>
                </>
            ),
        },
    ];

    return (
        <>

            <div className="col-xl-12 mb-12 mb-xl-0">
                <div className="card">
                    <h5 className="card-header">Facilities List </h5>
                    <div className="card-body">
                        <DataTable
                            columns={columns}
                            data={facilities}
                            pagination
                            highlightOnHover
                            striped
                        />
                    </div>
                </div>
            </div>

            {/* Edit FAQ Modal */}
            < Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Facility</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditFacilityForm onSubmit={handleSubmit} facility={currentFacility} />
                </Modal.Body>
            </Modal >
        </>
    );
};

export default FacilityList;
