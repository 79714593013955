import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../i18n';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { url, Image } from '../../Components/Common/Url';
import { ToastContainer, toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { Link, useNavigate, useParams } from 'react-router-dom';




const VitaminCategoryForm = () => {
    const params = useParams();
    const { id } = params;


    const { token } = useSelector((state) => state.user);
    const { t, i18n } = useTranslation();
    const [activeLanguage, setActiveLanguage] = useState(i18n.language);
    const [title, setTitle] = useState('');
    const [image, setImage] = useState('');

    const navigate = useNavigate()



    const handleDrop = useCallback(acceptedFiles => {
        // Assuming you only want to handle one file at a time
        const file = acceptedFiles[0];
        if (file.type.startsWith('image/')) {
            // Do something with the valid image file
            console.log('Valid image file:', file);
            setImage(file)
        } else {
            // Handle invalid file type
            toast.error('Invalid file type. Please upload an image file.');
        }
    }, []);


    const { getRootProps, getInputProps } = useDropzone({
        accept: ['image/*'],
        onDrop: handleDrop
    });

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setActiveLanguage(lng);


    };

    const handleStep = async () => {
        if (!title) {
            return toast.error('Please Enter Title')
        }
        const formData = new FormData();

        formData.append('lan', activeLanguage);
        formData.append('title', title);
        formData.append('image', image);



        try {
            const { data } = await axios.post(`${url}/api/v1/vitamin/category/add`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'token': `${token}`
                },
            });

            toast.success(data.message);
            if (data.status == true) {
                window.location.reload()
            }
        } catch (error) {
            toast.error(error.message);
        }
    }


    useEffect(() => {
        if (id) {
            const get_step_data = async () => {
                const { data } = await axios.get(`${url}/api/v1/vitamin/category/get/${id}`, {
                    headers: {
                        'token': `${token}`
                    },
                });
                console.log(data)
                if (activeLanguage === 'ar') {

                    setTitle(data.data.ar_title)
                    setImage(data.data.ar_image)


                } else {

                    setTitle(data.data.en_title)
                    setImage(data.data.en_image)


                }
            }
            get_step_data()
        } else {

            setTitle('')
            setImage('')

        }
    }, [id, activeLanguage])

    const handleStepUpdate = async () => {

        const formData = new FormData();
        formData.append('lan', activeLanguage);
        formData.append('title', title);
        formData.append('image', image);


        try {
            const { data } = await axios.put(`${url}/api/v1/vitamin/category/update/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'token': `${token}`
                },
            });

            toast.success(data.message);
            if (data.status == true) {
                window.location.reload()
            }
        } catch (error) {
            toast.error(error.message);
        }
    }

    const handleTitle = (e) => {
        setTitle(e.target.value)
        
    }


    return (
        <div className="card mb-3">
            <ToastContainer />

            <div className="card-header pt-2">
                <h4>{id ? `Edit Category ${title}` : 'Add Category'} </h4>
                <ul className="nav nav-tabs card-header-tabs" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className={`nav-link ${activeLanguage === 'en' ? 'active' : ''}`} onClick={() => changeLanguage('en')} >English</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className={`nav-link ${activeLanguage === 'ar' ? 'active' : ''}`} onClick={() => changeLanguage('ar')} >Arabic</button>
                    </li>
                </ul>
            </div>

            <div className="tab-content">
                <div className="tab-pane fade active show" role="tabpanel">
                    <div>
                        <div className="row g-3">
                            <div className="col-md-12">
                                <label className="form-label" >{t('Title')}</label>
                                <input
                                    type="text"
                                    dir={activeLanguage == 'ar' ? 'rtl' : 'ltr'}
                                    className="form-control"
                                    name="name"
                                    value={title}
                                    onChange={(e) => handleTitle(e)}
                                />

                            </div>
                            <div className="col-md-12">
                                <label className="form-label">{t('Image')}</label><br />
                                


                                <div {...getRootProps()} style={dropzoneStyles}>

                                    <input {...getInputProps()} />
                                    <p>{t('Drag and drop an image file here, or click to select one')}</p>
                                </div>
                                {image && image.path}
                                {
                                    image && <img src={`${Image}/${image}`} width={"150px"} className='mt-4'/>
                                }

                            </div>



                        </div>
                        <div class="pt-4">
                            {
                                id ? <button type="submit" class="btn btn-primary me-sm-3 me-1 waves-effect waves-light" onClick={handleStepUpdate}>{t('Update')}</button>
                                    : <button type="submit" class="btn btn-primary me-sm-3 me-1 waves-effect waves-light" onClick={handleStep}>{t('Submit')}</button>
                            }


                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
};

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
};

export default VitaminCategoryForm;
