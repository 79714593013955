import React from "react";
import AddOffers from "./AddOffers";
import { useLocation } from "react-router-dom";

export default function Offfers() {
  const location = useLocation();
  const data = location.state.type || {};
  console.log(data);
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <AddOffers type={data} />
      </div>
    </div>
  );
}
