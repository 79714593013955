import React, { useEffect, useState } from "react";
import { url } from "../../Components/Common/Url";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const Forms = () => {
  const { token } = useSelector((state) => state.user);
  const [forms, setForms] = useState([]);
  const [filteredForms, setFilteredForms] = useState([]);
  const [searchText, setSearchText] = useState("");

  const fetchForms = async () => {
    const response = await fetch(`${url}/api/v1/contactus/get/forms`, {
      method: "GET",
      headers: {
        token: `${token}`,
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();
    setForms(result.data);
    setFilteredForms(result.data); // Initialize filteredForms
  };

  useEffect(() => {
    fetchForms();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filteredData = forms.filter(
      (form) =>
        form.slug?.toLowerCase().includes(value) ||
        form.formData?.name?.toLowerCase().includes(value) ||
        form.formData?.email?.toLowerCase().includes(value) ||
        form.formData?.mobile?.toLowerCase().includes(value) ||
        form.formData?.message?.toLowerCase().includes(value)
    );

    setFilteredForms(filteredData);
  };

  const exportToExcel = () => {
    const data = filteredForms.map((form, index) => ({
      "#": index + 1,
      "Lead From": form?.slug?.toUpperCase() || "-",
      Name: form.formData?.name || "-",
      Email: form.formData?.email || "-",
      Mobile: form.formData?.mobile || "-",
      Message: form.formData?.message || "-",
      "Created At": formatDate(form.createdAt),
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Lead Forms");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "LeadForms.xlsx");
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "60px",
    },
    {
      name: "Lead From",
      selector: (row) => row?.slug?.toUpperCase() || "-",
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.formData?.name || "-",
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.formData?.email || "-",
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.formData?.mobile || "-",
      sortable: true,
    },
    {
      name: "Message",
      selector: (row) => row.formData?.message || "-",
    },
    {
      name: "Created At",
      selector: (row) => formatDate(row.createdAt),
      sortable: true,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // Override the row height
      },
    },
    headCells: {
      style: {
        backgroundColor: "#f4f4f4",
        fontSize: "16px",
        fontWeight: "bold",
        color: "#333",
        padding: "8px",
      },
    },
    cells: {
      style: {
        padding: "8px",
      },
    },
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="col">
          <div className="card mb-3">
            <h5 className="card-header">
              Lead Forms
              <button
                onClick={exportToExcel}
                className="btn btn-primary float-end"
              >
                Export to Excel
              </button>
            </h5>
            <div className="card-body">
              <input
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={handleSearch}
                className="form-control mb-3"
              />
              <DataTable
                title=""
                columns={columns}
                data={filteredForms}
                pagination
                highlightOnHover
                customStyles={customStyles}
                responsive
                defaultSortFieldId={1}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forms;
