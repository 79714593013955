import React from 'react'
import DownloadForm from '../../Forms/Download/DownloadForm'
import DownloadListComponent from '../../Components/Download/DownloadListComponent'


export default function DownloadPage() {
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
    <div className="row g-3">
        <div className="col-md-4">
           <DownloadForm />

        </div>
        <div className="col-md-8">
         <DownloadListComponent />
        </div>

    </div>
</div>
  )
}
