import React from 'react'
import ServiceForm from '../../Forms/AsterAtHome/ServiceForm'
import ServiceListComponent from '../../Components/AsterAtHome/ServiceListComponent'

export default function Service() {
    return (
        <>
            <div className="container-xxl flex-grow-1 container-p-y">

                <div className="row">
                    <ServiceForm />
                    <ServiceListComponent />
                </div>
            </div>
        </>

    )
}
