import React, { useState } from 'react';
import { Modal, Button, Badge } from 'react-bootstrap';
import SpecialityFAQForm from './SpecialityFAQForm'; // Import the form component

const SpecialityFAQList = ({ faqs, onDelete, onUpdateFAQ , language}) => {
  const [show, setShow] = useState(false);
  const [currentFAQ, setCurrentFAQ] = useState(null);

  const handleEdit = (faq) => {
    setCurrentFAQ(faq);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setCurrentFAQ(null);
  };

  const handleSubmit = (values) => {
    if (currentFAQ) {
      onUpdateFAQ(currentFAQ._id, values);
      handleClose();
    }
  };

  return (
    <>
      <div className="row mt-5">
        <div className="col-xl-12 mb-12 mb-xl-0">
          <div className="card">
            <h5 className="card-header">FAQ List  {faqs.length > 0 ? `(${faqs.length})` : ''}
            </h5>
          <div className="card-body">
            {faqs.length > 0 ? (
              <ul className="timeline mb-0">
                {faqs.map(faq => (
                  <li className="timeline-item timeline-item-transparent" key={faq._id}>
                    <span className="timeline-point timeline-point-primary"></span>
                    <div className="timeline-event">
                      <div className="timeline-header mb-3 d-flex justify-content-between align-items-center">
                        <h6 className="mb-0">{faq.question}</h6>
                        <div>
                          {faq.status === 'publish' ? (
                            <Button size="sm" variant="success" className="me-2">Published</Button>
                          ) : (
                            <Button size="sm" variant="danger" className="me-2">Unpublished</Button>
                          )}
                          <Button
                            variant="info"
                            size="sm"
                            className="me-2"
                            onClick={() => handleEdit(faq)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => onDelete(faq._id)}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                      <p className="mb-2">{faq.answer}</p>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No FAQ Found</p>
            )}
          </div>
        </div>
      </div>
    </div >

      {/* Edit FAQ Modal */ }
      < Modal show = { show } onHide = { handleClose } >
        <Modal.Header closeButton>
          <Modal.Title>Edit FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SpecialityFAQForm onSubmit={handleSubmit} faq={currentFAQ} language={language} />
        </Modal.Body>
      </Modal >
    </>
  );
};

export default SpecialityFAQList;
