import React from "react";
import AddCategoryForm from "../../../Forms/Blog/Category/AddCategoryForm";
import CategoryListComponent from "../../../Components/Blog/Category/CategoryListComponent";
import { useLocation } from "react-router-dom";
export default function AddCategory() {
  const location = useLocation();
  const data = location.state.type || {};
  console.log(data);
  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-lg-4">
            <AddCategoryForm type={data} />
          </div>
          <div className="col-lg-8">
            <CategoryListComponent type={data} />
          </div>
        </div>
      </div>
    </>
  );
}
