
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify'; // Optional for notifications
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import axios from 'axios';
import { useSelector } from 'react-redux';
import { url } from '../../Components/Common/Url';


const DownloadListComponent = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const { token } = useSelector((state) => state.user)

  const get_excellance_story = async () => {
    try {
      const { data } = await axios.get(`${url}/api/v1/download/get/`, {
        headers: {
          'token': `${token}`
        },
      });
      console.log(data)
      setData(data.data)
    } catch (error) {
      console.log(error)
    }
  };

  const handleEdit = (id) => {

    navigate(`/admin/download/${id}`)
  }

  const handleDelete = async (id) => {

    const confirm = window.confirm('Are you sure to delete this execallance story?')
    if (confirm) {
      const { data } = await axios.delete(`${url}/api/v1/download/delete/${id}`, {
        headers: {
          'token': `${token}`
        },
      })
      if (data.status == true) {
        toast.success(data.message)
        get_excellance_story()
      } else {
        toast.error(`Something went wrong!`)
      }
    }



  }

  useEffect(() => {
    get_excellance_story();
  }, [])

  const handlestatus = async (id, s) => {
    let status = 0;
    if(s ==1){
      status = 0;
    }else{
      status = 1;
    }
    const { data } = await axios.post(`${url}/api/v1/download/status/${id}`, {
      status: status
    }, {
      headers: {
        'token': `${token}`
      },
    })
    if (data.status == true) {
      toast.success(data.message)
      get_excellance_story();
    } else {
      toast.error(`Something went wrong!`)
    }
  }





  const columns = [
    {
      name: '#',
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: 'Title (English)',
      selector: row => row.en_title,
      sortable: true,
    },
    {
      name: 'Title (Arabic)',
      selector: row => row.ar_title,
      sortable: true,
    },

    {
      name: 'Status',
      cell: row => (
        <>
          <label class="switch">
            <input type="checkbox" class="switch-input" checked={row.status == 1 ? 'true' : ''} onClick={() => handlestatus(row._id, row.status)} />
            <span class="switch-toggle-slider">
              {
                row.status == 1 ? <span class="switch-on">
                  <i class="ti ti-check"></i>
                </span> : <span class="switch-off">
                  <i class="ti ti-x"></i>
                </span>
              }


            </span>
            <span class="switch-label">{row.status == 1 ? 'Published' : 'Draft'}</span>
          </label>
        </>
      ),
    },
    {
      name: 'Actions',
      cell: row => (
        <>

          <button className="btn btn-primary btn-sm me-3" onClick={() => handleEdit(row._id, row.status)}>Edit</button>
          <button className="btn btn-danger btn-sm" onClick={() => handleDelete(row._id, row.status)}>Delete</button>
        </>
      ),
    },
  ];

  return (
    <div className="card mb-3">
      <h5 className="card-header">Download List</h5>
      <div className="card-body">
        <DataTable
          columns={columns}
          data={data}
          pagination
          highlightOnHover
          striped
        />
      </div>
    </div>
  );
};

export default DownloadListComponent;
