// src/components/ExcellanceComponent.js
import React, { useState } from 'react';

import ExcellanceForm from '../../Forms/ExcellanceForm';
import { Link, useLocation } from 'react-router-dom';
import ExcellanceList from '../../Page/Excellance/ExcellanceList';

export default function ExcellanceComponent() {

return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row g-3">
                <div className="col-md-4">
                    <ExcellanceForm />

                </div>
                <div className="col-md-8">
                 <ExcellanceList />
                </div>

            </div>
        </div>
    );
}
