import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { useSelector } from "react-redux";
import axios from "axios";
import { url, Image } from "../../Components/Common/Url";
import { ToastContainer, toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import PdfUpload from "./../PdfUpload";
import { Link, useNavigate, useParams } from "react-router-dom";

const DownloadForm = () => {
  const params = useParams();
  const { id } = params;

  const { token } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [error, seError] = useState(null);
  const [singleData, setSingleData] = useState({});
  const navigate = useNavigate();
  const [seoTitleEn, setSeoTitleEn] = useState("");
  const [seoKeywordsEn, setSeoKeywordsEn] = useState("");
  const [seoDescEn, setSeoDescEn] = useState("");
  const [seoTitleAr, setSeoTitleAr] = useState("");
  const [seoKeywordsAr, setSeoKeywordsAr] = useState("");
  const [seoDescAr, setSeoDescAr] = useState("");

  const handleDrop = useCallback((acceptedFiles) => {
    // Assuming you only want to handle one file at a time
    const file = acceptedFiles[0];
    if (file.type.startsWith("image/")) {
      // Do something with the valid image file
      console.log("Valid image file:", file);
      setImage(file);
    } else {
      // Handle invalid file type
      toast.error("Invalid file type. Please upload an image file.");
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ["image/*"],
    onDrop: handleDrop,
  });

  const changeLanguage = (lng) => {
    console.log(singleData);
    i18n.changeLanguage(lng);
    setActiveLanguage(lng);
  };

  const handleExcellance = async () => {
    if (!title) {
      return toast.error("Please Enter Title");
    }
    const formData = new FormData();
    formData.append("lan", activeLanguage);
    formData.append("title", title);
    formData.append("image", image);
    formData.append("pdf", pdf);

    formData.append("seoTitleEn", seoTitleEn);
    formData.append("seoKeywordsEn", seoKeywordsEn);
    formData.append("seoDescEn", seoDescEn);
    formData.append("seoTitleAr", seoTitleAr);
    formData.append("seoKeywordsAr", seoKeywordsAr);
    formData.append("seoDescAr", seoDescAr);

    try {
      const { data } = await axios.post(
        `${url}/api/v1/download/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      if (data.status == true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error("Failed to upload files.");
    }
  };

  useEffect(() => {
    if (id) {
      const get_excellanace_data = async () => {
        const { data } = await axios.get(`${url}/api/v1/download/get/${id}`, {
          headers: {
            token: `${token}`,
          },
        });
        console.log(activeLanguage);
        if (activeLanguage === "ar") {
          setTitle(data.data.ar_title);
          setImage(data.data.ar_image);
          setPdf(data.data.ar_pdf);

          setSeoKeywordsAr(data.data.seoKeywordsAr);
          setSeoDescAr(data.data.seoDescAr);
          setSeoTitleAr(data.data.seoTitleAr);
        } else {
          setTitle(data.data.en_title);
          setImage(data.data.en_image);
          setPdf(data.data.en_pdf);
          setSeoKeywordsEn(data.data.seoKeywordsEn);
          setSeoDescEn(data.data.seoDescEn);
          setSeoTitleEn(data.data.seoTitleEn);
        }
      };
      get_excellanace_data();
    } else {
      setTitle("");
      setImage("");
      setPdf("");
    }
  }, [id, activeLanguage]);

  const handleExcellanceUpdate = async () => {
    const formData = new FormData();
    formData.append("lan", activeLanguage);
    formData.append("title", title);
    formData.append("image", image);
    formData.append("pdf", pdf);

    formData.append("seoTitleEn", seoTitleEn);
    formData.append("seoKeywordsEn", seoKeywordsEn);
    formData.append("seoDescEn", seoDescEn);
    formData.append("seoTitleAr", seoTitleAr);
    formData.append("seoKeywordsAr", seoKeywordsAr);
    formData.append("seoDescAr", seoDescAr);

    try {
      const { data } = await axios.put(
        `${url}/api/v1/download/update/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `${token}`,
          },
        }
      );

      toast.success(data.message);
      if (data.status == true) {
        window.location.reload();
      }
    } catch (error) {
      toast.error("Failed to upload files.");
    }
  };

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <ToastContainer />
      <div className="row">
        <div className="col">
          <div className="card mb-3">
            <div className="card-header pt-2">
              <h4> {id ? `Edit Download ${id}` : "Add Download"}</h4>
              <ul className="nav nav-tabs card-header-tabs" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeLanguage === "en" ? "active" : ""
                    }`}
                    onClick={() => changeLanguage("en")}
                  >
                    English
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeLanguage === "ar" ? "active" : ""
                    }`}
                    onClick={() => changeLanguage("ar")}
                  >
                    Arabic
                  </button>
                </li>
              </ul>
            </div>

            <div className="tab-content">
              <div className="tab-pane fade active show" role="tabpanel">
                <div>
                  <div className="row g-3">
                    <div className="col-md-12">
                      <label className="form-label">{t("Title")}</label>
                      <input
                        dir={activeLanguage === "ar" ? "rtl" : "ltr"}
                        type="text"
                        className="form-control"
                        name="name"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label">{t("Image")}</label>
                      <br />

                      <div {...getRootProps()} style={dropzoneStyles}>
                        <input {...getInputProps()} />
                        <p>
                          Drag 'n' drop an image file here, or click to select
                          one
                        </p>
                      </div>
                      {image && image.path}

                      {image && (
                        <img
                          src={`${Image}/${image}`}
                          width={"250px"}
                          className="mt-4"
                        />
                      )}
                    </div>

                    <div className="col-md-12">
                      <label className="form-label">{t("PDF")}</label>
                      {pdf && (
                        <Link to={`${Image}/${pdf}`} target="_blink">
                          View
                        </Link>
                      )}

                      <PdfUpload pdf={pdf} setPdf={setPdf} setError={seError} />
                      {pdf && pdf.path}
                      {error && <span style={{ color: "red" }}>{error}</span>}
                    </div>
                    {activeLanguage === "en" ? (
                      <>
                        <div className="card mb-3 mt-3">
                          <h5 className="card-header">SEO (En)</h5>

                          <div class="card-body">
                            <div className="mb-3">
                              <label className="form-label">Keywords</label>
                              <input
                                value={seoKeywordsEn}
                                onChange={(ev) =>
                                  setSeoKeywordsEn(ev.target.value)
                                }
                                className="form-control"
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label">Description</label>
                              <input
                                value={seoDescEn}
                                onChange={(ev) => setSeoDescEn(ev.target.value)}
                                className="form-control"
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label">Title</label>
                              <input
                                value={seoTitleEn}
                                onChange={(ev) =>
                                  setSeoTitleEn(ev.target.value)
                                }
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="card mb-3">
                          <h5 className="card-header">SEO (Ar)</h5>

                          <div class="card-body">
                            <div className="mb-3">
                              <label className="form-label">Keywords</label>
                              <input
                                value={seoKeywordsAr}
                                onChange={(ev) =>
                                  setSeoKeywordsAr(ev.target.value)
                                }
                                className="form-control"
                                dir="rtl"
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label">Description</label>
                              <input
                                value={seoDescAr}
                                onChange={(ev) => setSeoDescAr(ev.target.value)}
                                className="form-control"
                                dir="rtl"
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label">Title</label>
                              <input
                                value={seoTitleAr}
                                onChange={(ev) =>
                                  setSeoTitleAr(ev.target.value)
                                }
                                className="form-control"
                                dir="rtl"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div class="pt-4">
                    {id ? (
                      <button
                        type="submit"
                        class="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                        onClick={handleExcellanceUpdate}
                      >
                        {t("Update")}
                      </button>
                    ) : (
                      <button
                        type="submit"
                        class="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
                        onClick={handleExcellance}
                      >
                        {t("Submit")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default DownloadForm;
