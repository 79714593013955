import React from 'react'
import StepForm from '../../Forms/RtPcr/StepForm'
import StepListComponent from '../../Components/RtPcr/StepListComponent'


export default function StepPage() {
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
       <StepForm />
       <StepListComponent />
      </div>
    </div>
  )
}
