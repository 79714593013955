import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ImageDropzone from "../Forms/Fields/ImageDropzone";
import "../i18n";
import { Tab, Nav } from "react-bootstrap";
import FormServices from "../Services/FormServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// Options for the status dropdown
const statusOptions = [
  { value: "publish", label: "Publish" },
  { value: "draft", label: "Draft" },
  { value: "pending", label: "Pending" },
];

const getValidationSchema = (key) => {
  return Yup.object().shape({
    titleEn:
      key === "english"
        ? Yup.string().required("Title is required")
        : Yup.string(),
    titleAr:
      key === "arabic"
        ? Yup.string().required("Title is required")
        : Yup.string(),
    descriptionEn:
      key === "english"
        ? Yup.string().required("Description is required")
        : Yup.string(),
    descriptionAr:
      key === "arabic"
        ? Yup.string().required("Description is required")
        : Yup.string(),
    status: Yup.string().required("Status is required"),
    imageEn:
      key === "english"
        ? Yup.mixed().required("An image is required")
        : Yup.mixed(),
    imageAr:
      key === "arabic"
        ? Yup.mixed().required("An image is required")
        : Yup.mixed(),
  });
};

const AddSpecialityForm = () => {
  const navigate = useNavigate();
  const [key, setKey] = useState("english");

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="col">
          <div className="card mb-3">
            <h5 className="card-header">Add Speciality</h5>
            <div className="card-header pt-2">
              <Formik
                initialValues={{
                  titleEn: "",
                  titleAr: "",
                  descriptionEn: "",
                  descriptionAr: "",
                  status: "",
                  imageEn: "",
                  imageAr: "",
                  slug: "",
                  seoTitleEn: "",
                  seoKeywordsEn: "",
                  seoDescEn: "",
                  seoTitleAr: "",
                  seoKeywordsAr: "",
                  seoDescAr: "",
                }}
                validationSchema={() => getValidationSchema(key)}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  try {
                    await FormServices.CreateSpecialityService(values);
                    toast.success("Speciality has been added successfully!");
                    resetForm();
                    navigate("/admin/specialities-list");
                  } catch (error) {
                    console.error("Error submitting form:", error);
                  } finally {
                    setSubmitting(false);
                  }
                }}
              >
                {({ setFieldValue, isSubmitting }) => (
                  <Form className="container mt-4">
                    <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                      <Nav variant="tabs">
                        <Nav.Item>
                          <Nav.Link eventKey="english">English</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="arabic">Arabic</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="english">
                          <div className="mb-3">
                            <label htmlFor="titleEn" className="form-label">
                              Title (English)
                            </label>
                            <Field
                              name="titleEn"
                              type="text"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="titleEn"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="mb-3">
                            <label htmlFor="slug" className="form-label">
                              slug (English)
                            </label>
                            <Field
                              name="slug"
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Description (English)
                            </label>
                            <CKEditor
                              editor={ClassicEditor}
                              data=""
                              onChange={(event, editor) => {
                                setFieldValue(
                                  "descriptionEn",
                                  editor.getData()
                                );
                              }}
                              config={{
                                toolbar: [
                                  "heading",
                                  "|",
                                  "bold",
                                  "italic",
                                  "|",
                                  "link",
                                  "bulletedList",
                                  "numberedList",
                                  "|",
                                  "indent",
                                  "outdent",
                                  "|",
                                  "imageUpload",
                                  "blockQuote",
                                  "insertTable",
                                  "mediaEmbed",
                                  "|",
                                  "undo",
                                  "redo",
                                  "|",
                                  "selectAll",
                                ],
                                heading: {
                                  options: [
                                    {
                                      model: "paragraph",
                                      title: "Paragraph",
                                      class: "ck-heading_paragraph",
                                    },
                                    {
                                      model: "heading1",
                                      view: "h1",
                                      title: "Heading 1",
                                      class: "ck-heading_heading1",
                                    },
                                    {
                                      model: "heading2",
                                      view: "h2",
                                      title: "Heading 2",
                                      class: "ck-heading_heading2",
                                    },
                                    {
                                      model: "heading3",
                                      view: "h3",
                                      title: "Heading 3",
                                      class: "ck-heading_heading3",
                                    },
                                    {
                                      model: "heading4",
                                      view: "h4",
                                      title: "Heading 4",
                                      class: "ck-heading_heading4",
                                    },
                                    {
                                      model: "heading5",
                                      view: "h5",
                                      title: "Heading 5",
                                      class: "ck-heading_heading5",
                                    },
                                    {
                                      model: "heading6",
                                      view: "h6",
                                      title: "Heading 6",
                                      class: "ck-heading_heading6",
                                    },
                                  ],
                                },
                              }}
                            />
                            <ErrorMessage
                              name="descriptionEn"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="card mb-3">
                            <h5 className="card-header">SEO</h5>

                            <div class="card-body">
                              <div className="mb-3">
                                <label className="form-label">Keywords</label>
                                <Field
                                  className="form-control"
                                  name="seoKeywordsEn"
                                />
                              </div>
                              <div className="mb-3">
                                <label className="form-label">
                                  Description
                                </label>
                                <Field
                                  className="form-control"
                                  name="seoDescEn"
                                />
                              </div>
                              <div className="mb-3">
                                <label className="form-label">Title</label>
                                <Field
                                  className="form-control"
                                  name="seoTitleEn"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Image (English)
                            </label>
                            <ImageDropzone
                              onFilesAdded={(acceptedFiles) => {
                                if (acceptedFiles.length > 0) {
                                  console.log(acceptedFiles[0]);
                                  setFieldValue("imageEn", acceptedFiles[0]);
                                }
                              }}
                            />
                            <ErrorMessage
                              name="imageEn"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="arabic">
                          <div className="mb-3">
                            <label htmlFor="titleAr" className="form-label">
                              Title (Arabic)
                            </label>
                            <Field
                              dir="rtl"
                              name="titleAr"
                              type="text"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="titleAr"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="mb-3">
                            <label htmlFor="slug" className="form-label">
                              Slug (Arabic)
                            </label>
                            <Field
                              // dir="rtl"
                              name="slug"
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">
                              Description (Arabic)
                            </label>
                            <CKEditor
                              config={{
                                language: {
                                  ui: "ar", // UI language
                                  content: "ar", // Content language
                                },
                                contentsLangDirection: "rtl", // Content direction
                                toolbar: [
                                  "heading",
                                  "|",
                                  "bold",
                                  "italic",
                                  "|",
                                  "link",
                                  "bulletedList",
                                  "numberedList",
                                  "|",
                                  "indent",
                                  "outdent",
                                  "|",
                                  "imageUpload",
                                  "blockQuote",
                                  "insertTable",
                                  "mediaEmbed",
                                  "|",
                                  "undo",
                                  "redo",
                                  "|",
                                  "selectAll",
                                ],
                                heading: {
                                  options: [
                                    {
                                      model: "paragraph",
                                      title: "Paragraph",
                                      class: "ck-heading_paragraph",
                                    },
                                    {
                                      model: "heading1",
                                      view: "h1",
                                      title: "Heading 1",
                                      class: "ck-heading_heading1",
                                    },
                                    {
                                      model: "heading2",
                                      view: "h2",
                                      title: "Heading 2",
                                      class: "ck-heading_heading2",
                                    },
                                    {
                                      model: "heading3",
                                      view: "h3",
                                      title: "Heading 3",
                                      class: "ck-heading_heading3",
                                    },
                                    {
                                      model: "heading4",
                                      view: "h4",
                                      title: "Heading 4",
                                      class: "ck-heading_heading4",
                                    },
                                    {
                                      model: "heading5",
                                      view: "h5",
                                      title: "Heading 5",
                                      class: "ck-heading_heading5",
                                    },
                                    {
                                      model: "heading6",
                                      view: "h6",
                                      title: "Heading 6",
                                      class: "ck-heading_heading6",
                                    },
                                  ],
                                },
                              }}
                              editor={ClassicEditor}
                              data=""
                              onChange={(event, editor) => {
                                setFieldValue(
                                  "descriptionAr",
                                  editor.getData()
                                );
                              }}
                            />
                            <ErrorMessage
                              name="descriptionAr"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                          <div className="card mb-3">
                            <h5 className="card-header">SEO</h5>
                            <div class="card-body">
                              <div className="mb-3">
                                <label className="form-label">Keywords</label>
                                <Field
                                  className="form-control"
                                  name="seoKeywordsAr"
                                  dir="rtl"
                                />
                              </div>
                              <div className="mb-3">
                                <label className="form-label">
                                  Description
                                </label>
                                <Field
                                  className="form-control"
                                  name="seoDescAr"
                                  dir="rtl"
                                />
                              </div>
                              <div className="mb-3">
                                <label className="form-label">Title</label>
                                <Field
                                  className="form-control"
                                  name="seoTitleAr"
                                  dir="rtl"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Image (Arabic)</label>
                            <ImageDropzone
                              onFilesAdded={(acceptedFiles) => {
                                if (acceptedFiles.length > 0) {
                                  console.log(acceptedFiles[0]);
                                  setFieldValue("imageAr", acceptedFiles[0]);
                                }
                              }}
                            />
                            <ErrorMessage
                              name="imageAr"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </Tab.Pane>
                        <div className="mb-3">
                          <label className="form-label">Status</label>
                          <Select
                            options={statusOptions}
                            onChange={(option) =>
                              setFieldValue("status", option.value)
                            }
                          />
                          <ErrorMessage
                            name="status"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isSubmitting}
                          >
                            Submit
                          </button>
                        </div>
                      </Tab.Content>
                    </Tab.Container>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSpecialityForm;
