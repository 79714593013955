import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import '../../i18n';

import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { url } from '../../Components/Common/Url';
import { Link, useParams } from 'react-router-dom';


const CounterForm = () => {
    const { token } = useSelector((state) => state.user)
    const params = useParams()
    const { id } = params;

    const { t, i18n } = useTranslation();
    const [activeLanguage, setActiveLanguage] = useState(i18n.language);
    const [title, setTitle] = useState("");
    const [number, setNumber] = useState(null);
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setActiveLanguage(lng);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!title) {
            return toast.error(`Please Enter Title`)
        }
        const response = await fetch(`${url}/api/v1/counter/add`, {
            method: "POST",
            headers: {
                "token": `${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                lan: activeLanguage, title , number

            })
        })
        const result = await response.json()

        toast(result.message)
        if (result.status == true) {
            setInterval(() => {
                window.location.reload()
            }, 3000);
        }


    };



    const get_signle_office = async () => {
        const response = await fetch(`${url}/api/v1/counter/get/counter/${id}`, {
            method: "GET",
            headers: {
                "token": `${token}`,

            },

        })
        const result = await response.json()
        console.log('single data', result)
        if (activeLanguage == 'ar') {
            setTitle(result.data.ar_title)
           
        } else {
            setTitle(result.data.en_title)
        }
        setNumber(result.data.number)
    }

    useEffect(() => {
        if (id) {
            get_signle_office()
        }else{
            setTitle('') 
            setNumber('')
        }

    }, [id, activeLanguage])



    const handleupdate = async (e) => {
        e.preventDefault()
        try {
            const response = await fetch(`${url}/api/v1/counter/${id}`, {
                method: "PUT",
                headers: {
                    "token": `${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    lan: activeLanguage, title , number

                })

            })

            const result = await response.json();
            console.log(result)
            toast.success(result.message)
            if (result.status == true) {
                setInterval(() => {
                    window.location.reload()
                }, 3000);
            }
        } catch (error) {
            console.log('error', error)
            toast.error(error)
        }
    }






    return (
        <div className="col-md-4">
            <ToastContainer />
            <div className="card mb-3">
                <div className="card-header pt-2">
                   
                    <ul className="nav nav-tabs card-header-tabs" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className={`nav-link ${activeLanguage === 'en' ? 'active' : ''}`} onClick={() => changeLanguage('en')} >English</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={`nav-link ${activeLanguage === 'ar' ? 'active' : ''}`} onClick={() => changeLanguage('ar')} >Arabic</button>
                        </li>
                    </ul>
                </div>

                <div className="tab-content">
                    <div className="tab-pane fade active show" role="tabpanel">
                        <form onSubmit={id ? handleupdate : handleSubmit}>
                            <div className="row g-3">
                            <div className='col-lg-12'>
                                    <label className="form-label">{t('Number')}</label>
                                    <input  dir={activeLanguage === "ar" ? "rtl" : "ltr"} type="number" className="form-control" value={number} onChange={(e) => setNumber(e.target.value)} />
                                </div>
                                <div className='col-lg-12'>
                                    <label className="form-label">{t('Title')}</label>
                                    <input  dir={activeLanguage === "ar" ? "rtl" : "ltr"} type="text" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} />
                                </div>

                            </div>
                            <div class="pt-4">
                                <button type="submit" class="btn btn-primary me-sm-3 me-1 waves-effect waves-light">{t('Submit')}</button>

                            </div>
                        </form>
                    </div>

                </div>

            </div>
        </div>
    );
};

export default CounterForm;
