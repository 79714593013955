import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify'; // Optional for notifications
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import axios from 'axios';
import { useSelector } from 'react-redux';
import { url } from '../../Components/Common/Url';

const ExcellanceList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState('');
  const { token } = useSelector((state) => state.user);

  const get_excellance_story = async () => {
    try {
      const { data } = await axios.get(`${url}/api/v1/excellance/get/`, {
        headers: {
          'token': `${token}`
        },
      });
      console.log(data);
      setData(data.data);
      setFilteredData(data.data); // Initialize filtered data
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    get_excellance_story();
  }, []);

  useEffect(() => {
    const result = data.filter(story =>
      story.en_title.toLowerCase().includes(search.toLowerCase()) ||
      story.ar_title.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredData(result);
  }, [search, data]);

  const handleEdit = (id) => {
    navigate(`/admin/excellance/${id}`);
  };

  const handleDelete = async (id) => {
    const confirm = window.confirm('Are you sure to delete this excellance story?');
    if (confirm) {
      try {
        const { data } = await axios.delete(`${url}/api/v1/excellance/delete/${id}`, {
          headers: {
            'token': `${token}`
          },
        });
        if (data.status === true) {
          toast.success(data.message);
          get_excellance_story();
        } else {
          toast.error('Something went wrong!');
        }
      } catch (error) {
        toast.error('Failed to delete the story.');
      }
    }
  };

  const handlestatus = async (id, s) => {
    const status = s === 1 ? 0 : 1;
    try {
      const { data } = await axios.post(`${url}/api/v1/excellance/status/${id}`, {
        status: status
      }, {
        headers: {
          'token': `${token}`
        },
      });
      if (data.status === true) {
        toast.success(data.message);
        get_excellance_story();
      } else {
        toast.error('Something went wrong!');
      }
    } catch (error) {
      toast.error('Failed to update status.');
    }
  };

  const columns = [
    {
      name: '#',
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: 'Title (English)',
      selector: row => row.en_title,
      sortable: true,
    },
    {
      name: 'Title (Arabic)',
      selector: row => row.ar_title,
      sortable: true,
    },
    {
      name: 'Status',
      cell: row => (
        <>
          <label className="switch">
            <input
              type="checkbox"
              className="switch-input"
              checked={row.status === 1}
              onChange={() => handlestatus(row._id, row.status)}
            />
            <span className="switch-toggle-slider">
              {row.status === 1 ? (
                <span className="switch-on">
                  <i className="ti ti-check"></i>
                </span>
              ) : (
                <span className="switch-off">
                  <i className="ti ti-x"></i>
                </span>
              )}
            </span>
            <span className="switch-label">{row.status === 1 ? 'Published' : 'Draft'}</span>
          </label>
        </>
      ),
    },
    {
      name: 'Actions',
      cell: row => (
        <>
          <button className="btn btn-primary btn-sm me-3" onClick={() => handleEdit(row._id)}>Edit</button>
          <button className="btn btn-danger btn-sm" onClick={() => handleDelete(row._id)}>Delete</button>
        </>
      ),
    },
  ];

  return (
    <div className="card mb-3">
      <h5 className="card-header">Excellance Story List</h5>
      <div className="card-body">
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Search by title..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          highlightOnHover
          striped
        />
      </div>

      
    </div>
  );
};

export default ExcellanceList;
