import React from "react";
import BlogListComponent from "../../../Components/Blog/Category/BlogListComponent";
import { useLocation } from "react-router-dom";
export default function BlogListPage() {
  const location = useLocation();
  const data = location.state.type || {};
  console.log(data);
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="col">
          <BlogListComponent type={data} />
        </div>
      </div>
    </div>
  );
}
