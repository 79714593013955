import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify"; // Optional for notifications
import { useNavigate } from "react-router-dom"; // Use useNavigate instead of useHistory
import axios from "axios";
import { url } from "../../Common/Url";
import { useSelector } from "react-redux";

const CategoryListComponent = ({ type }) => {
  const { token } = useSelector((state) => state.user);
  const [category, setCategory] = useState([]);

  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  const get_category = async () => {
    const { data } = await axios.get(`${url}/api/v1/blog/category/get/`, {
      headers: {
        token: `${token}`,
      },
    });
    console.log(data);
    setCategory(data.data);
  };

  useEffect(() => {
    get_category();
  }, []);

  const handleStatus = async (id, s) => {
    try {
      let status = 0;
      if (s == 1) {
        status = 0;
      } else {
        status = 1;
      }
      const formData = new FormData();
      formData.append("status", status);

      const { data } = await axios.post(
        `${url}/api/v1/blog/category/status/change/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            token: `${token}`,
          },
        }
      );

      console.log(data);
      toast.success(data.message);
      get_category();
    } catch (error) {
      toast.error(error);
    }
  };
  const handledelete = async (id) => {
    const confirm = window.confirm("Are you sure to delete this category");
    if (confirm) {
      try {
        const { data } = await axios.delete(
          `${url}/api/v1/blog/category/delete/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              token: `${token}`,
            },
          }
        );
        console.log(data);
        toast.success(data.message);
        get_category();
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleEdit = async (id) => {
    navigate(`/admin/blog/category/add/${id}`);
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Title (English)",
      selector: (row) => row.en_title,
      sortable: true,
    },
    {
      name: "Title (Arabic)",
      selector: (row) => row.ar_title,
      sortable: true,
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          <label class="switch">
            <input
              type="checkbox"
              class="switch-input"
              checked={row.status == 1 ? "true" : ""}
              onClick={() => handleStatus(row._id, row.status)}
            />
            <span class="switch-toggle-slider">
              {row.status == 1 ? (
                <span class="switch-on">
                  <i class="ti ti-check"></i>
                </span>
              ) : (
                <span class="switch-off">
                  <i class="ti ti-x"></i>
                </span>
              )}
            </span>
            <span class="switch-label">
              {row.status == 1 ? "Published" : "Draft"}
            </span>
          </label>
        </>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <button
            className="btn btn-primary btn-sm me-3"
            onClick={() => handleEdit(row._id, row.status)}
          >
            Edit
          </button>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => handledelete(row._id, row.status)}
          >
            Delete
          </button>
        </>
      ),
    },
  ];

  return (
    <div className="card mb-3">
      <h5 className="card-header">
        {type && type === "event" ? "Feature List" : "Category List"}
      </h5>
      <div className="card-body">
        <DataTable
          columns={columns}
          data={category}
          pagination
          highlightOnHover
          striped
        />
      </div>
    </div>
  );
};

export default CategoryListComponent;
