import React from 'react'
import OfficeForm from '../../Forms/AsterAtHome/OfficeForm'
import OfficeList from './OfficeList'

export default function Office() {
  return (
    <div className="container-xxl flex-grow-1 container-p-y">

      <div className="row">
        <OfficeForm />
        <OfficeList />
      </div>
    </div>
  )
}
