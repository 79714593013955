import React from 'react'
import SeniorCarebenefitForm from '../../Forms/PatientCare/SeniorCareBenefitForm'
import SeniorCareBenefitListComponent from '../../Components/PatientCare/SeniorCareBenefitListCompponent'

export default function SeniorCareBenefitPage() {
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
       <SeniorCarebenefitForm />
       <SeniorCareBenefitListComponent />
      </div>
    </div>
  )
}
