import React from 'react'
import FaqForm from '../../Forms/RtPcr/FaqForm'
import FaqListComponent from '../../Components/RtPcr/FaqListComponent'

export default function FaqPage() {
    return (
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row">
                <FaqForm />
                <FaqListComponent/>
            </div>
        </div>
    )
}
